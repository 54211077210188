import React from "react";
import StaticPageContainer from "../../components/static-page";

function ContactPage() {
  return (
    <StaticPageContainer title="Contact Us">
      <p>
        <b>If you have questions about property taxes, please call (909) 387-8308.</b>
      </p>
      <div>
        If you have school district debt questions, or questions about the County Treasury Pool Composition
        and/or Performance, including:
        <ul>
          <li>Quarterly yields for the portfolio</li>
          <li>Holdings in the portfolio </li>
          <li>Credit concerns or issues</li>
        </ul>
        Please contact Parth Bhatt at (909) 382-3033.
      </div>
      <br />
      <div>
        If you have questions on any of the following:
        <ul>
          <li>General Interest Apportionment </li>
          <li>Interest Apportionment Reports on this website </li>
          <li>Deposits or Wires </li>
          <li>General Banking Issues</li>
          <li>Other General Information</li>
        </ul>
        Please contact Wendy Sieruga at (909) 382-3008.
      </div>
      <br />
      <p>
        <b>
          The Treasurer division cannot answer questions related to property taxes. For tax related questions,
          please call (909) 387-8308.
        </b>
      </p>

      {/* TODO: change path links to updated ones */}
      <div>
        You can view our locations and hours{" "}
        <a href="https://www.sbcounty.gov/ATC/Home/Locations#panel2">here</a>. For any other questions, please
        click <a href="https://www.sbcounty.gov/ATC/Home/Contact">here</a>.
      </div>
    </StaticPageContainer>
  );
}

export default ContactPage;
