import React from "react";
import RfiForm from "../../components/treasurer/rfi-form";
import StaticPageContainer from "../../components/static-page";

export default function SubmitRFI() {
  return (
    <StaticPageContainer title="Brokers / Dealers RFI Application Form">
      <p>Use the form below to upload completed applications and documents.</p>
      <RfiForm />
    </StaticPageContainer>
  );
}
