import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedinIn, faTwitter, faYelp } from '@fortawesome/free-brands-svg-icons'
import React from 'react'


const Footer = () => {
    return (
        <footer className="footer mt-auto mx-auto py-3">
            <div className='d-flex justify-content-center'>
                <div className='d-md-flex justify-content-center my-4'>
                    <img className='footer-logo img-fluid mb-4' src='/assets/logos/sb-logo-full-color.png' alt="San Bernardino County Logo"></img>
                    <svg className='footer-separator d-none d-md-block' width="90" height="200">
                        <line x1="20" y1="0" x2="20" y2="200"></line>
                    </svg>
                    <div className=''>
                        <img className='footer-atc img-fluid mb-4' src='/assets/images/department-name.png' alt="Auditor-Controller/Treasurer/Tax Collector"></img>
                        <p className='lead mb-0 text-light'>268 W Hospitality Lane</p>
                        <p className='lead mb-0 text-light'>San Bernardino, CA 92415</p>
                        <p className='lead mb-0 text-light'>(909) 387-8308</p>
                        <p className='lead mb-0 text-light'> 8:00 to 5:00 PM, M-F</p>
                    </div>
                </div>
            </div>
            {/* <div className='d-flex justify-content-center my-4'>
                <div className=''>
                    <h4 className='text-secondary upper-case'>STAY CONNECTED</h4>
                    <div className='d-flex'>
                        <input className='email' placeholder='ENTER YOUR EMAIL ADDRESS'></input>
                        <button type='button' className='btn btn-primary btn-md'>SUBMIT</button>
                    </div>
                </div>
            </div> */}
            <div className='container fluid text-light my-4'>
                <div className='row'>
                    <ul className='col-6 col-md-2 list-unstyled'>
                        <li className='h6'>Tax Collector</li>
                        <li>Important Dates</li>
                        <li>Pay Your Tax Bill</li>
                        <li>TOT</li>
                        <li>Penalty Cancellation Form</li>
                        <li>Tax Sale</li>
                    </ul>
                    <ul className=' col-6 col-md-2 list-unstyled'>
                        <li className='h6'>Property Tax</li>
                        <li>Where do my Property Tax Dollars Go?</li>
                        <li>Unclaimed Refund Claim</li>
                        <li>Annual Breakdown</li>
                        <li>Reports and Publications</li>
                    </ul>
                    <ul className=' col-6 col-md-2 list-unstyled'>
                        <li className='h6'>Divisions</li>
                        <li>Treasurer</li>
                        <li>Disbursements</li>
                        <li>Revenue Recovery</li>
                        <li>Controller</li>
                        <li>Information Technology</li>
                        <li>Auditor</li>
                        <li>SAP Center of Excellence</li>
                    </ul>
                    <ul className=' col-6 col-md-2 list-unstyled'>
                        <li className='h6'>General</li>
                        <li>About ATC</li>
                        <li>News</li>
                        <li>Locations and Hours</li>
                        <li><a href="contact-us" style={{ color: 'white', textDecoration:'none' }}>Contact Us</a></li>
                        <li>Publications</li>
                        <li>Careers</li>
                    </ul>
                    <ul className=' col-6 col-md-2 list-unstyled'>
                        <li className='h6'>Online Services</li>
                        <li>Pay Your Tax Bill</li>
                        <li>Online Documents</li>
                        <li>CountyBillPay.com</li>
                        <li>Get Electronic Tax Bill</li>
                    </ul>
                    <ul className=' col-6 col-md-2 list-unstyled'>
                        <li className='h6'>Useful Links</li>
                        <li>Assessor-Recorder</li>
                        <li>District Attorney</li>
                        <li>Sherrif</li>
                        <li>Report Fraud and Waste</li>
                        <li>Board of Supervisors</li>
                    </ul>
                </div>
            </div>
            <div className='d-flex justify-content-center my-4'>
                <FontAwesomeIcon
                    className="text-secondary m-2 social-icons"
                    icon={faFacebook}
                    size="2x" />
                <FontAwesomeIcon
                    className="text-secondary  m-2  social-icons"
                    icon={faTwitter}
                    size="2x" />
                <FontAwesomeIcon
                    className="text-secondary  m-2 social-icons"
                    icon={faInstagram}
                    size="2x" />
                <FontAwesomeIcon
                    className="text-secondary  m-2 social-icons"
                    icon={faYelp}
                    size="2x" />
                <FontAwesomeIcon
                    className="text-secondary  m-2 social-icons"
                    icon={faLinkedinIn}
                    size="2x" />
            </div>
            <div className='d-flex justify-content-center text-light upper-case px-2 text-center'>
                <small>2023 San Bernardino County Auditor Controller/Treasurer/tax collector - all rights reserved</small>
            </div>
        </footer >
    )
}

export default Footer