import React, { useEffect } from "react";
import PropTypes from "prop-types";
import WelcomeSection from "../welcome";
import Breadcrumbs from "../breadcrumbs";

TreasurerLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

function TreasurerLayout({ children, ...props }) {
  return (
    <WelcomeSection title="Treasurer" description="Welcome to San Bernardino County's ">
      <Breadcrumbs />
      {children}
    </WelcomeSection>
  );
}

export default TreasurerLayout;
