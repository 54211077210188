import React from "react";
import { Link } from "react-router-dom";
import StaticPageContainer from "../../components/static-page";

export default function RFI() {
  return (
    <StaticPageContainer title="Brokers / Dealers RFI Process">
      <p style={{ paddingBottom: "10px" }}>
        Only broker/dealers that are on the County's formal approved broker/dealer list may transact
        securities with the County Treasurer's Office.
      </p>
      <div>
        The County Treasurer's Office uses a competitive process to select new broker/dealers. Periodically,
        the Treasurer's Office conducts a Request for Information (RFI) process in which broker/dealers are
        required to provide information on their practice, financial condition and other factors. This process
        ensures an open, competitive process for all interested prospective brokers to compete for placement
        on the County's approved list. Some of the factors that are considered when selecting new
        broker/dealers include:
        <div style={{ padding: "10px" }}>
          <ul>
            <li>Relevant experience of individual representatives to serve the County's account</li>
            <li>Legal and regulator issues</li>
            <li>Financial condition of firm</li>
            <li>Types and amount of inventory of products provided</li>
            <li>Scope of ancillary services provided including economic analysis, Internet service, etc.</li>
            <li>Experience with other municipal/governmental clients</li>
          </ul>
        </div>
      </div>
      {/* TODO: add link to download document */}
      <p style={{ paddingBottom: "10px" }}>
        For firms interested in working with the County Treasurer's Office in the future, please complete the{" "}
        <b>
          <a href="" download>
            Broker/Dealer Application
          </a>
        </b>
        .
      </p>
      <p style={{ paddingBottom: "10px" }}>
        For questions or further information about this process, Please contact Parth Bhatt, Investment
        officer at (909) 382-3033 or Robin Dixon, Assistant Investment Officer at (909) 382-3030.
      </p>
      <b>
        <Link to="/treasurer/rfi/form">Submit/Upload Completed Application and Documents</Link>
      </b>
    </StaticPageContainer>
  );
}
