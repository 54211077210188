import React from "react";
import ExecMemberCard from "../../components/exec-components/exec-member-card";
import WelcomeSection from "../../components/welcome";

const ExecTeam = () => {
    return (
        <WelcomeSection>
            <div className="d-flex justify-content-center align-items-center container">
                <div>
                    <h1 className="display-4 text-center m-2 m-md-4" >Meet ATC's Executive Team</h1>
                    <small>ATC Mason’s Executive Management Team has over 400 years of combined expertise in accounting, finance, information technology, law, auditing of County departments, offices, districts and special districts, private firm & public agency leadership, private & public strategic investing and money management, tax collection, accurate processing and disbursing of County employee wages and payroll related taxes, property tax apportionment and redevelopment successor entity fund management. ATC leadership has the power and expertise to effectively serve the people of San Bernardino County!</small>
                    <div className="d-flex justify-content-center">
                        <ExecMemberCard
                            imgSrc="/assets/images/team/em.png"
                            name="Ensen Mason, CPA, CFA"
                            position="Auditor-Controller/Treasurer/Tax Collector">
                            <small>San Bernardino County Auditor-Controller/Treasurer/Tax Collector Ensen Mason was elected in June 2018 after a 30-year career as a Certified Public Accountant and Investment Manager.

                                In his role as Auditor-Controller/Treasurer/Tax Collector, Mr. Mason oversees 329 employees in directing the financial affairs of the County and managing an investment pool of over $9 billion. He also is an ex officio member on the San Bernardino County Employees’ Retirement Association Board of Retirement and helps manage their more than $10 billion portfolio of investments. </small>
                        </ExecMemberCard>

                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="me-5">
                            <ExecMemberCard imgSrc="/assets/images/team/asst-atc1.png"
                                name="John Johnson"
                                position="Assistant Auditor-Controller/Treasurer/Tax Collector"
                            >

                                <small>John Johnson joined the San Bernardino County Treasury Division in 2004 and was appointed Chief Deputy Treasurer in 2008. He is also serving as Assistant ATC. Prior to his County service, Mr. Johnson spent 15 years in the private sector as a registered securities representative. He holds a B.S. degree in Business Administration from the University of Southern Mississippi and is an active member of the California Association of County Treasurers and Tax Collectors, California Municipal Treasurers Association and the Investment Managers of Public Agencies Council.</small>
                            </ExecMemberCard>
                        </div>
                        <div >
                            <ExecMemberCard
                                imgSrc="/assets/images/team/asst-atc-2.jpeg"
                                name="Diana Atkeson"
                                position="Assistant Auditor-Controller/Treasurer/Tax Collector"
                            >
                                <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Convallis convallis tellus id interdum velit laoreet id. Tincidunt tortor aliquam nulla facilisi cras fermentum odio. Velit laoreet id donec ultrices. Sagittis eu volutpat odio facilisis mauris sit amet. Neque viverra justo nec ultrices dui sapien eget mi proin. Amet nisl suscipit adipiscing bibendum. Non nisi est sit amet facilisis magna etiam tempor orci. Massa tempor nec feugiat nisl pretium fusce id. Dictumst quisque sagittis purus sit amet volutpat consequat mauris. </small>
                            </ExecMemberCard>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                        <ExecMemberCard imgSrc="/assets/images/team/auditor-chief.png"
                            name=" Denise Mejico"
                            position={" Chief Deputy, Auditor"}>
                            <small>Denise Mejico has served as ATC Chief Deputy Auditor since June 2013. Denise began her career in 1999 as an Accountant with the Orange County Auditor-Controller. She joined the San Bernardino County Auditor-Controller's office in 2005 as Accounts Payable Manager. She subsequently served as Internal Audits Manager and Chief Deputy, Disbursements. Denise holds a B.S. degree in Accounting from Cal Poly Pomona.</small>


                        </ExecMemberCard>
                        <ExecMemberCard imgSrc="/assets/images/team/rr-chief.png"
                            name=" Sonia Hermosillo"
                            position={" Chief Deputy, Revenue Recovery"}>
                            <small>Sonia Hermosillo has been with the County of San Bernardino since 2000 and has served the Auditor-Controller/Treasurer/Tax Collector in a number of management capacities. She is currently Division Chief of Revenue Recovery overseeing the revenue enhancement operation that serves Tax Collector, ARMC, Superior Courts and other county departments. Sonia’s professional career has spanned over 30 years with experience in external auditing, tax preparation and Healthcare Finance. She is a graduate of Cal Poly Pomona, and holds a Bachelor’s Degree in Business Administration with a major in Accounting</small>
                        </ExecMemberCard>
                        <ExecMemberCard imgSrc="/assets/images/team/controller-chief.png"
                            name=" Vanessa Doyle"
                            position={" Chief Deputy, Controller"}>
                            <small>Vanessa Doyle has served as ATC Chief Deputy Controller since March 2018. She began her career in San Bernardino County as an Accountant in the Recorder Division in 2005. She promoted through the ranks, serving in various capacities in Accounts Payable, Internal Audits and Reimbursable Projects. She promoted to Manager, Property Tax Division and then in June 2013 to Chief Deputy, Disbursements. She subsequently served as Chief Deputy Tax Collector, Chief Deputy Revenue Recovery Division and Chief Deputy, Property Tax. She holds a B.S. in Business Administration with a concentration in Accounting from Cal State University, San Bernardino.</small>
                        </ExecMemberCard>
                        <ExecMemberCard imgSrc="/assets/images/team/disb-chief.png"
                            name="Rhawnie Berg"
                            position={" Chief Deputy, Disbursements"}>
                            <small>Rhawnie Berg has served as ATC Chief Deputy Disbursements since October 2021. Rhawnie began her career with the Auditor-Controller/Treasurer/Tax Collector in 2005, as an Accountant I in the Internal Audits Section. She promoted through the ranks, serving in several different capacities throughout the department, including time in the Central Payroll, Accounts Payable, and Internal Audits Sections, as well as the Property Tax Division. Rhawnie holds a B.S in Accounting from California State University, San Bernardino, and a professional designation as an Accredited Payables Manager (APM).</small>
                        </ExecMemberCard>
                        <ExecMemberCard imgSrc="/assets/images/team/it-chief.png"
                            name="Don Le"
                            position={" Chief Deputy, Information Technology"}>
                            <small>Don Le has served as Chief Deputy, Information Technology for San Bernardino County Auditor-Controller/Treasurer/Tax Collector since July 2019. He is responsible for the management of Information Technology Division (ITD) and leading the division through many ongoing projects for the department to meet current and future organizational challenges.
                            </small>
                        </ExecMemberCard>
                        <ExecMemberCard imgSrc="/assets/images/team/pt-chief.png"
                            name="Franz Zyzz"
                            position={" Interim Chief Deputy, Property Tax"}>
                            <small>Neque sodales ut etiam sit amet nisl purus in mollis. Etiam erat velit scelerisque in dictum. Neque volutpat ac tincidunt vitae semper quis lectus. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi. Commodo ullamcorper a lacus vestibulum sed arcu non odio euismod. Aliquam nulla facilisi cras fermentum odio eu. Enim praesent elementum facilisis leo vel fringilla est ullamcorper. Justo donec enim diam vulputate ut pharetra. Lobortis scelerisque fermentum dui faucibus in. Faucibus a pellentesque sit amet porttitor eget. Nascetur ridiculus mus mauris vitae ultricies.</small>
                        </ExecMemberCard>
                        <ExecMemberCard imgSrc="/assets/images/team/sap-chief.png"
                            name="Rowena Barcelona-Nuqui"
                            position={" Chief Deputy, SAP Center of Excellence"}>
                            <small>Rowena Barcelona-Nuqui has served as SAP Chief Deputy since April 2018. She is responsible for managing the SAP Center of Excellence Department that provides support to all end-users of the county SAP ERP system. Prior to her employment with the County, Rowena had over 20 years of private sector experience using and supporting the SAP system with major companies including Nestle USA, Inc. and The Walt Disney Company. She holds a B.A. degree in Economics from the University of Southern California and an MBA from the University of Phoenix.</small>
                        </ExecMemberCard>
                        <ExecMemberCard imgSrc="/assets/images/team/tc-chief.png"
                            name="  Linda Mikulski"
                            position={" Chief Deputy, Tax Collector"}>
                            <small>Linda Y. Mikulski began her position as the ATC Chief Deputy Tax Collector in November 2021. She is a California-licensed CPA with over 18 years of professional experience in accounting, auditing, and management. She most recently worked in healthcare finance and has extensive knowledge in accounting operations, budget preparation, and financial statement analysis. Linda joined the San Bernardino County Auditor-Controller’s office in 2019 as ATC Accounting Manager for the Revenue Recovery Division Accounting and Cashiering support units.</small>
                        </ExecMemberCard>
                        <ExecMemberCard imgSrc="/assets/images/team/treasurer-chief.jpg"
                            name="Parth Bhatt"
                            position={" Chief Deputy, Treasurer"}>
                            <small>Parth Bhatt joined the San Bernardino County Treasurer Division in 2016 and was appointed Interim Chief Deputy Treasurer in January 2023. Prior to his San Bernardino County service, Parth served as Portfolio Manager for Orange County and worked in the private sector as a Portfolio Management Associate and an Assistant Portfolio Manager. He holds a Bachelor of Arts and a Master of Arts in Economics from California State University, Fullerton, and a diploma in computer engineering from Mumbai University. He is also a Chartered Financial Analyst Level III candidate.</small>
                        </ExecMemberCard>
                    </div>





                </div >
            </div >
        </WelcomeSection>
    )
}
export default ExecTeam;