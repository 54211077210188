import React from 'react'



function UpcomingEvent({ month, day, children, event, ...props }) {
    return (
        <>
            <div className="event-date">
                <div className="row">
                    <div className="col-3">
                        <div className="calendar-div">
                            <h5 className="fs-6 text-uppercase" align="center">{month}</h5>
                            <h5 align="center">{day}</h5>
                        </div>
                    </div>
                    <div className="col-9 event-description">
                        <p>{event}</p>
                    </div>
                </div>
            </div>
            <div>
                {children}
            </div>
        </>
    )
}



export default UpcomingEvent







