import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { AccountCircle, ExpandLess, ExpandMore, Menu, Close } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom/dist";
import { divisionItemsList } from "../../utils/division-items";
import { onlineServiceItemsList } from "../../utils/online-services-items";
import { navbarList } from "../../utils/navbar-items";

const drawerWidth = "400px";
const collapseColor = "aliceblue";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const MenuSubHeader = ({ title, icon, onClick, ...props }) => (
    <Box>
      <Button
        variant="text"
        component={RouterLink}
        onClick={onClick}
        sx={{ width: "100%", justifyContent: "space-between", fontWeight: 700, px: 0, py: 2 }}
        {...props}
      >
        <div className="d-flex">
          <ListItemIcon sx={{ minWidth: "35px", color: "#1c6889" }}> {icon} </ListItemIcon>
          <Typography sx={{ color: "#1c6889" }}>{title}</Typography>
        </div>
        {title === "Online Services" && (openOnlineServices ? <ExpandLess /> : <ExpandMore />)}
        {title === "Divisions" && (openDivisions ? <ExpandLess /> : <ExpandMore />)}
      </Button>
    </Box>
  );

  const CustomMenuItem = ({ children, onClick, ...props }) => (
    <ListItemButton
      component={RouterLink}
      onClick={onClick}
      sx={{ pl: 2, py: 0, fontWeight: "bold", color: "#333333" }}
      {...props}
    >
      <ListItemText
        primary={children}
        primaryTypographyProps={{ fontWeight: "bold", color: "#333333" }}
        sx={{ textTransform: "uppercase" }}
      />
    </ListItemButton>
  );

  const CustomListItem = ({ children, onClick, ...props }) => (
    <ListItemButton component={RouterLink} onClick={onClick} sx={{ pl: 4, py: 0 }} {...props}>
      <ListItemText primary={children} />
    </ListItemButton>
  );

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openOnlineServices, setOnlineServicesOpen] = useState(false);
  const [openDivisions, setDivisionsOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDivisions = () => {
    setDivisionsOpen(!openDivisions);
  };
  const handleOnlineServices = () => {
    setOnlineServicesOpen(!openOnlineServices);
  };

  return (
    <>
      <CssBaseline />
      <div className="nav-logo-wrapper">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ height: "100%", mx: 2, p: 0 }}
        >
          <Menu />
          <img className="nav-logo" src="/assets/logos/sb-logo-full-color.png" alt="SBC ATC Logo" />
        </IconButton>
      </div>

      <Drawer
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          zIndex: 1601,
        }}
        PaperProps={{
          sx: {
            backgroundColor: "white",
            color: "black",
          },
        }}
      >
        <DrawerHeader
          className="nav-logo-wrapper justify-content-between"
          sx={{ backgroundColor: "#003366" }}
        >
          <img className="nav-logo" src="/assets/logos/sb-logo-full-color.png" alt="SBC ATC Logo" />
          <IconButton onClick={handleDrawerClose} sx={{ mx: 1 }}>
            <Close style={{ color: "white" }} />
          </IconButton>
        </DrawerHeader>

        <List sx={{ px: 3 }}>
          {navbarList.map((item, i) => (
            <div key={i}>
              <MenuSubHeader
                title={item.subheader}
                icon={item.icon}
                to={item.subheaderLink}
                onClick={() => {
                  item.subheader === "Online Services"
                    ? handleOnlineServices()
                    : item.subheader === "Divisions"
                    ? handleDivisions()
                    : handleDrawerClose();
                }}
              />

              {item.subheader === "Online Services" && (
                <Collapse in={openOnlineServices} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding sx={{ mb: 2 }}>
                    {onlineServiceItemsList.map((list, j) => (
                      <div key={j} className="py-2">
                        {list.subheader && <CustomMenuItem>{list.subheader}</CustomMenuItem>}
                        {list.items.map((item, k) => (
                          <CustomListItem key={k} to={item.itemLink} onClick={handleDrawerClose}>
                            {item.item}
                          </CustomListItem>
                        ))}
                      </div>
                    ))}
                  </List>
                </Collapse>
              )}

              {item.subheader === "Divisions" && (
                <Collapse in={openDivisions} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding sx={{ mb: 2 }}>
                    {divisionItemsList.map((division, j) => (
                      <div key={j} className="py-2">
                        <CustomMenuItem onClick={handleDrawerClose} to={division.subheaderLink}>
                          {division.subheader}
                        </CustomMenuItem>
                        {division.items.map((item, k) => (
                          <CustomListItem key={k} to={item.itemLink} onClick={handleDrawerClose}>
                            {item.item}
                          </CustomListItem>
                        ))}
                      </div>
                    ))}
                  </List>
                </Collapse>
              )}

              <Divider />
            </div>
          ))}

          <MenuSubHeader title={"Login"} icon={<AccountCircle />} to={"#"} />
        </List>
      </Drawer>
    </>
  );
}
