import { useEffect, useState } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { fetchPowerBIEmbed } from "../../services/powerbi-service";
import "../../components/treasurer/dashboard.scss";

const reportId = process.env.REACT_APP_POWERBI_REPORT_ID;
/**
 * PowerBiPOC component is responsible for embedding a Power BI report into the UI.
 * It retrieves the necessary configuration and access tokens, and renders the PowerBIEmbed component.
 */
const PowerBiPOC = () => {
  const [reportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: "",
    accessToken: "",
    id: reportId,
    tokenType: models.TokenType.Embed,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: true,
        },
      },
      background: models.BackgroundType.Transparent,
    },
  });

  /**
   * useEffect hook to fetch the report configuration and set the necessary state variables.
   * It runs only once when the component is mounted.
   */
  useEffect(() => {
    getEmbed()
  }, []);

  const getEmbed = async () => {
    try {
      const response = await fetchPowerBIEmbed();
      setReportConfig({
        ...reportConfig,
        embedUrl: response.embedUrl,
        accessToken: response.embedToken,
      });

    } catch (error) {
      console.error("Error fetching PowerBI data:", error);
    };
  };

  return (
    <>
      {reportConfig.embedUrl !== "" && reportConfig.accessToken !== "" && (
        <PowerBIEmbed embedConfig={reportConfig} cssClassName="report-container" />
      )}
    </>
  );
};
export default PowerBiPOC;
