import { useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom/dist";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { IMaskInput } from "react-imask";
import { emailRegex } from "../../utils/utils";
import RfiTextField from "./rfi-textfield";

export default function RfiForm() {
  const navigate = useNavigate();
  const [secondaryChecked, setSecondaryChecked] = useState(true);
  const [primaryAddressSuggestions, setPrimaryAddressSuggestions] = useState([]);
  const [secondaryAddressSuggestions, setSecondaryAddressSuggestions] = useState([]);
  const [openCancel, setOpenCancel] = useState(false);

  const handleSecondaryCheckboxChange = (event) => {
    setSecondaryChecked(event.target.checked);
  };

  const validationSchema = yup.object({
    firmName: yup.string().required("Firm Name is required"),
    primaryFirstName: yup.string().required("First Name is required"),
    primaryLastName: yup.string().required("Last Name is required"),
    primaryTitle: yup.string().required("Title is required"),
    primaryPhone: yup.string().required("Phone Number is required"),
    primaryEmail: yup
      .string()
      .email("Invalid email address")
      .required("Email is required")
      .test("email", "Invalid email address", (email) => {
        return emailRegex.test(email);
      }),
    primaryAddress: yup.string().required("Address is required"),
    secondaryFirstName: secondaryChecked ? yup.string().required("First Name is required") : yup.string(),
    secondaryLastName: secondaryChecked ? yup.string().required("Last Name is required") : yup.string(),
    secondaryTitle: secondaryChecked ? yup.string().required("Title is required") : yup.string(),
    secondaryPhone: secondaryChecked ? yup.string().required("Phone Number is required") : yup.string(),
    secondaryEmail: secondaryChecked
      ? yup
          .string()
          .email("Invalid email address")
          .required("Email is required")
          .test("email", "Invalid email address", (email) => {
            return emailRegex.test(email);
          })
      : yup.string(),
    secondaryAddress: secondaryChecked ? yup.string().required("Address is required") : yup.string(),
    file: yup
      .mixed()
      .test("required", "You need to provide a file", (file) => {
        return file ? true : false;
      })
      .test("fileType", "Only PDF files are accepted", (file) => {
        return file && file.type === "application/pdf";
      }),
  });

  const formik = useFormik({
    initialValues: {
      firmName: "",
      primaryFirstName: "",
      primaryLastName: "",
      primaryTitle: "",
      primaryPhone: "",
      primaryEmail: "",
      primaryAddress: undefined,
      secondaryFirstName: "",
      secondaryLastName: "",
      secondaryTitle: "",
      secondaryPhone: "",
      secondaryEmail: "",
      secondaryAddress: undefined,
      notes: "",
      file: undefined,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // TODO: submit form data to backend
      if (!secondaryChecked) {
        values.secondaryFirstName = "";
        values.secondaryLastName = "";
        values.secondaryTitle = "";
        values.secondaryPhone = "";
        values.secondaryEmail = "";
        values.secondaryAddress = "";
      }
      alert(JSON.stringify(values, null, 2));
    },
  });

  // useEffect(() => {mb: 4
  //   myForm.setFieldTouched("firmName", true);
  // }, []);

  const onAddressChange = (type, value) => {
    let url = `https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=${value}&f=json&token=${process.env.REACT_APP_ARCGIS_KEY}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network Response was not ok");
        }
        return response.json();
      })
      .then((myJson) => {
        if (typeof myJson.message !== "undefined") {
          throw myJson.message;
        } else {
          type === "primary"
            ? setPrimaryAddressSuggestions(myJson.suggestions)
            : setSecondaryAddressSuggestions(myJson.suggestions);
        }
      });
  };

  const PhoneMask = forwardRef(function PhoneMask(props, ref) {
    const { id, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        overwrite
        onAccept={(value) => {
          if (id === "primaryPhone") {
            if (value.length === 14 && formik.values.primaryPhone === "") {
              formik.setFieldValue("primaryPhone", value);
            } else if (value.length === 0) {
              formik.setFieldValue("primaryPhone", "");
            }
          }
          if (id === "secondaryPhone") {
            if (value.length === 14 && formik.values.secondaryPhone === "") {
              formik.setFieldValue("secondaryPhone", value);
            } else if (value.length === 0) {
              formik.setFieldValue("secondaryPhone", "");
            }
          }
        }}
      />
    );
  });

  return (
    <div className="py-3">
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            "& .MuiTextField-root": {
              m: 1,
              width: {
                xs: "calc(100% - 16px)",
                sm: "calc(50% - 16px)",
                lg: "calc(100% / 3 - 16px)",
              },
            },
            mb: 4,
          }}
        >
          <h3>Firm Name</h3>
          <RfiTextField formik={formik} id="firmName" label="Firm Name" />
        </Box>

        <Box
          sx={{
            "& .MuiTextField-root": {
              m: 1,
              width: {
                xs: "calc(100% - 16px)",
                sm: "calc(50% - 16px)",
                lg: "calc(100% / 3 - 16px)",
              },
            },
            mb: 4,
          }}
        >
          <h3>Primary Contact</h3>
          <RfiTextField formik={formik} id="primaryFirstName" label="First Name" />
          <RfiTextField formik={formik} id="primaryLastName" label="Last Name" />
          <RfiTextField formik={formik} id="primaryTitle" label="Title/Position" />
          <TextField
            id="primaryPhone"
            label="Phone Number"
            size="small"
            value={formik.values.primaryPhone}
            error={formik.touched.primaryPhone && Boolean(formik.errors.primaryPhone)}
            helperText={formik.touched.primaryPhone && formik.errors.primaryPhone}
            InputProps={{
              inputComponent: PhoneMask,
            }}
          />
          <RfiTextField formik={formik} id="primaryEmail" label="Email" />
          <Autocomplete
            id="primaryAddress"
            options={
              primaryAddressSuggestions === undefined || primaryAddressSuggestions.length === 0
                ? []
                : primaryAddressSuggestions.map((option) => option.text)
            }
            onInputChange={(e, value) => {
              onAddressChange("primary", value);
            }}
            onChange={(e, value) => {
              formik.setFieldValue("primaryAddress", value);
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  width: {
                    sm: "calc(100% - 16px) !important",
                    lg: "calc(100% / 3 * 2 - 16px) !important",
                  },
                }}
                {...params}
                label="Address"
                size="small"
                value={formik.values.primaryAddress}
                error={formik.touched.primaryAddress && Boolean(formik.errors.primaryAddress)}
                helperText={formik.touched.primaryAddress && formik.errors.primaryAddress}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            "& .MuiTextField-root": {
              m: 1,
              width: {
                xs: "calc(100% - 16px)",
                sm: "calc(50% - 16px)",
                lg: "calc(100% / 3 - 16px)",
              },
            },
            mb: 4,
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={secondaryChecked} onChange={handleSecondaryCheckboxChange} />}
              label="Add Secondary Contact"
            />
          </FormGroup>

          {secondaryChecked && (
            <>
              <h3>Secondary Contact</h3>
              <div>
                <RfiTextField formik={formik} id="secondaryFirstName" label="First Name" />
                <RfiTextField formik={formik} id="secondaryLastName" label="Last Name" />
                <RfiTextField formik={formik} id="secondaryTitle" label="Title/Position" />
                <TextField
                  id="secondaryPhone"
                  label="Phone Number"
                  size="small"
                  value={formik.values.secondaryPhone}
                  error={formik.touched.secondaryPhone && Boolean(formik.errors.secondaryPhone)}
                  helperText={formik.touched.secondaryPhone && formik.errors.secondaryPhone}
                  InputProps={{
                    inputComponent: PhoneMask,
                  }}
                />
                <RfiTextField formik={formik} id="secondaryEmail" label="Email" />
                <Autocomplete
                  id="secondaryAddress"
                  options={
                    secondaryAddressSuggestions === undefined || secondaryAddressSuggestions.length === 0
                      ? []
                      : secondaryAddressSuggestions.map((option) => option.text)
                  }
                  onInputChange={(e, value) => {
                    onAddressChange("secondary", value);
                  }}
                  onChange={(e, value) => {
                    formik.setFieldValue("secondaryAddress", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        width: {
                          sm: "calc(100% - 16px) !important",
                          lg: "calc(100% / 3 * 2 - 16px) !important",
                        },
                      }}
                      {...params}
                      label="Address"
                      size="small"
                      value={formik.values.secondaryAddress}
                      error={formik.touched.secondaryAddress && Boolean(formik.errors.secondaryAddress)}
                      helperText={formik.touched.secondaryAddress && formik.errors.secondaryAddress}
                    />
                  )}
                />
              </div>
            </>
          )}
        </Box>

        <Box sx={{ "& .MuiTextField-root": { m: 1, width: "calc(100% - 16px)" }, mb: 4 }}>
          <h3>Notes/Comments</h3>
          <TextField
            id="notes"
            name="notes"
            placeholder="Enter notes/comments"
            fullWidth
            multiline
            rows={2}
            value={formik.values.notes}
            onChange={formik.handleChange}
          />
        </Box>
        <div style={{ marginBottom: "35px" }}>
          <h3>Upload Broker/Dealer Application (PDF)</h3>
          <Box className="d-flex p-2 gap-3">
            <TextField
              id="file"
              type="file"
              onChange={(e) => {
                formik.setFieldValue("file", e.currentTarget.files[0]);
              }}
              error={formik.touched.file && Boolean(formik.errors.file)}
              helperText={formik.touched.file && formik.errors.file}
              inputProps={{
                accept: ".pdf",
              }}
            />
          </Box>
        </div>

        <div className="d-flex gap-2">
          <Button
            variant="outlined"
            size="large"
            onClick={() => {
              formik.dirty ? setOpenCancel(true) : navigate("/treasurer/rfi");
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" size="large" type="submit">
            Submit
          </Button>
        </div>

        <Dialog
          open={openCancel}
          onClose={() => {
            setOpenCancel(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" style={{ fontSize: "18px", textAlign: "center" }}>
            Are you sure you want to discard all changes?
          </DialogTitle>
          <DialogActions style={{ justifyContent: "center", fontWeight: "bold" }}>
            <Button
              autoFocus
              onClick={() => {
                setOpenCancel(false);
              }}
            >
              Disagree
            </Button>
            <Button
              onClick={() => {
                setOpenCancel(false);
                navigate("/treasurer/rfi");
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
