import React from "react";
import HomepageTemplate from "../../components/division-homepage/division-homepage-template";
import BasicWhiteCard from "../../components/basic-white-card";

function SAPHome(props) {
  const tileList = [
    { title: "SAP Center of Excellence Nav Tile 1", color: "four", link: "#" },
    { title: "SAP Center of Excellence Nav Tile 2", color: "five", link: "#" },
    { title: "SAP Center of Excellence Nav Tile 3", color: "one", link: "#" },
    { title: "SAP Center of Excellence Nav Tile 4", color: "two", link: "#" },
    { title: "SAP Center of Excellence Nav Tile 5", color: "two", link: "#" },
    { title: "SAP Center of Excellence Nav Tile 6", color: "three", link: "#" },
    { title: "SAP Center of Excellence Nav Tile 7", color: "five", link: "#" },
  ];

  return (
    <HomepageTemplate
      divisionCode="SAP"
      division="SAP Center of Excellence"
      chiefImage="assets/images/team/sap-chief.png"
      chiefName="Vanessa Doyle"
      divisionIntro="Rowena Barcelona-Nuqui has served as SAP Chief Deputy since April 2018. She is responsible for managing the SAP Center of Excellence Department that provides support to all end-users of the county SAP ERP system. Prior to her employment with the County, Rowena had over 20 years of private sector experience using and supporting the SAP system with major companies including Nestle USA, Inc. and The Walt Disney Company. She holds a B.A. degree in Economics from the University of Southern California and an MBA from the University of Phoenix."
      // aboutLink="#"
      // tileList={tileList}
      // linkList={linkList}
      additionalComponents={
        <BasicWhiteCard content="The SAP Center of Excellence (SAP CoE) provides support to all County departments in the use of the Enterprise Resource Planning software SAP. The SAP CoE is responsible for day-to-day support of the use the SAP system as well as designing, developing, and testing system enhancements, performing regular system maintenance and upgrades. Additionally, the SAP CoE provides end-user training on existing system processes as well as new system functionality. We strive to provide excellent customer service to all SAP users throughout the county." />
      }
    />
  );
}

export default SAPHome;
