import React from "react";
import TaxCollectorActionCard from "../../components/action-card/link-action-card";
import UpcomingEvent from "../../components/upcoming-event";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
    Box,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemButton,
    ListSubheader,
    Typography,
} from "@mui/material";
import AlertService from "../../components/alert-service/alert-service";
import { cardColors } from "../../utils/utils";
import DivisionLinks from "../../components/division-homepage/division-links";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import _ from 'lodash';
import { useEffect, useState } from "react";
import { getEntriesByContentType } from "../../services/contentful-service";




const cardList = [
    {
        title: "Pay Online",
        linkDestination: "/tax-collector/pay-online",
        cardColor: cardColors.one,
    },
    {
        title: "Bulk Payment",
        linkDestination: "#",
        cardColor: cardColors.five,
    },
    {
        title: "New Home Owner Information",
        linkDestination: "#",
        cardColor: cardColors.two,
    },
    {
        title: "Electronic Tax Bill",
        linkDestination: "#",
        cardColor: cardColors.three,
    },
    {
        title: "Transient Occupancy Tax",
        linkDestination: "#",
        cardColor: cardColors.two,
    },
    {
        title: "Property Tax",
        linkDestination: "#",
        cardColor: cardColors.three,
    },
    {
        title: "Mobile Home Tax Cleareance",
        linkDestination: "#",
        cardColor: cardColors.four,
    },
    {
        title: "Tax Sale",
        linkDestination: "#",
        cardColor: cardColors.five,
    },
];

const linkList = [
    { linkTitle: "Log In to my account", linkDestination: "#" },
    { linkTitle: "Estimate your tax bill", linkDestination: "#" },
    { linkTitle: "Tax Relief Programs", linkDestination: "#" },
    { linkTitle: "Where do my Property Tax Dollars Go?", linkDestination: "#" },
    { linkTitle: "Tax Collector Fee Schedule", linkDestination: "#" },
];

const contentType = "atcHoursOfOperation";

const Home = () => {
    const [atcHoursOfOperation, setatcHoursOfOperation] = useState();
    useEffect(() => {
        getData().then((response) => {
            debugger;
            setatcHoursOfOperation(response)
        })

    }, []);
    const getData = async () => {
        const result = await getEntriesByContentType(contentType);
        const dateNow = new Date();
        const startDate = new Date(new Date().getFullYear(), 3, 1);
        const endDate = new Date(new Date().getFullYear(), 3, 3);
        let title;
        // create a map of [asset IDs, assets] for efficient lookup

        const mappedInformation = result
            .filter((item) => {
                title = item.fields.title;
                return title === "Hours of Operation";
            })

            .map((item) => {
                return {
                    normalOperationHours: documentToReactComponents(item.fields.normalOperationHours),
                    specialOperationHours: documentToReactComponents(item.fields.specialOperationHours),
                    isSpecialHours: item.fields.isSpecialHours,

                };
            })


        if (startDate <= dateNow && endDate > dateNow) {
            mappedInformation[0].isSpecialHours = true;

        }

        return _.head(mappedInformation);

    };

    return (
        <div className="tax-collector container">
            <div className="row">
                <div className="mt-4">
                    <AlertService divisionCode="TXC"></AlertService>
                </div>

                <div className="col-md-7 col-lg-9 my-2">
                    <div className="container grid-container grid-style-4 py-5">
                        {cardList.map((card, index) => (
                            <TaxCollectorActionCard
                                key={index}
                                title={card.title}
                                linkDestination={card.linkDestination}
                                cardColor={card.cardColor}
                            />
                        ))}
                    </div>
                    <Box className="row py-5">
                        <DivisionLinks linkList={linkList} />
                    </Box>
                    <div className="d-flex justify-content-center align-content-left pb-5 my-5">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.1153994877436!2d-117.29152782283867!3d34.06655591697222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcad8248e6ba13%3A0x98750ba7c33ecac6!2sSan%20Bernardino%20County%20ATC!5e0!3m2!1sen!2sus!4v1681325129103!5m2!1sen!2sus"
                            width="75%"
                            height="390"
                            style={{ border: "0" }}
                            allowFullScreen=""
                            loading="lazy"
                            title="ATC Video"
                        ></iframe>
                    </div>
                </div>

                <div className="col-md-5 col-lg-3 my-2">
                    <div className="py-5">
                        {atcHoursOfOperation &&
                            <Card elevation={8}>
                                <CardContent>
                                    <Box sx={{ width: "100%", bgcolor: "background.paper", textAlign: "center" }}>
                                        <div>
                                            {atcHoursOfOperation.isSpecialHours ? (
                                                <div> {atcHoursOfOperation.specialOperationHours}</div>) : (<div>{atcHoursOfOperation.normalOperationHours}</div>)}
                                        </div>
                                    </Box>
                                </CardContent>
                            </Card>
                        }
                        <Card elevation={12} className="my-5">
                            <CardContent className="d-flex-column  justify-content-center align-items-center">
                                <div className="row">
                                    <div className="col-md-4"> </div>
                                    <div className="atc-img m-2 col-md-4 text-center">
                                        <img src="/assets/images/atc/ensen_mason2.jpg" className="img-fluid " alt="..." />
                                    </div>
                                </div>
                                <figure className="text-center mb-5">
                                    <blockquote className="blockquote">
                                        <h4>Welcome!</h4>
                                        <h5>I Care About Our Community and You</h5>
                                        <small>
                                            I am pleased to welcome taxpayers to the office and provide them with the outstanding
                                            customer service for which the Tax Collector is known. A variety of payment options are
                                            available for your convenience.{" "}
                                        </small>
                                    </blockquote>
                                    <figcaption className="blockquote-footer">
                                        <cite title="Source Title">Ensen Mason</cite>
                                    </figcaption>
                                </figure>
                            </CardContent>
                        </Card>
                        <div className={"mt-5"}>
                            <Card elevation={8}>
                                <CardContent>
                                    <List
                                        subheader={
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                <Typography variant="h5">Important Dates</Typography>
                                            </ListSubheader>
                                        }
                                    >
                                        <ListItem>
                                            <UpcomingEvent month="Jul" day="12" event="Beginning of fiscal year"></UpcomingEvent>
                                        </ListItem>
                                        <ListItem>
                                            <div className="calendar-m-div">
                                                <UpcomingEvent
                                                    month="Jul"
                                                    day=""
                                                    event="Unsecured tax bills are mailed"
                                                ></UpcomingEvent>
                                            </div>
                                        </ListItem>
                                        <ListItem>
                                            <UpcomingEvent month="Aug" day="1" event="Unsecured tax bills  due"></UpcomingEvent>
                                        </ListItem>
                                        <ListItem>
                                            <div className="calendar-m-div">
                                                <UpcomingEvent
                                                    month="Aug"
                                                    day=""
                                                    event="Supplemental tax bills  mailed"
                                                ></UpcomingEvent>
                                            </div>
                                        </ListItem>
                                        <ListItem>
                                            <UpcomingEvent month="Aug" day="1" event="Unsecured tax bills  due"></UpcomingEvent>
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};
export default Home;
