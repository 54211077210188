import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const LinkActionCardSub = ({ title, linkTitle, linkDestination, cardColor, children, icon, ...props }) => {
  return (
    <Card className={`w-100 h-100 link-action-card dark`} style={{ backgroundColor: "transparent" }}>
      <CardActionArea
        component={Link}
        to={linkDestination}
        aria-label={linkTitle}
        sx={{
          display: { xs: "flex", md: "block" },
          flexDirection: { xs: "column", md: "none" },

          height: { xs: "calc(210px + 3vw)", sm: "230px", lg: "270px" },
          border: "1.5px solid white",
          ":hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
        }}
      >
        <CardContent
          className="d-flex flex-column justify-content-center align-items-center text-center"
          sx={{ padding: { xs: "1rem", xl: "min(1.5vw, 3rem)" } }}
        >
          {icon}
          <Typography className="card-title m-0" gutterBottom component="div">
            {title}
          </Typography>
          <div className="card-body">{children}</div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
LinkActionCardSub.propTypes = {
  title: PropTypes.string.isRequired,
  linkTitle: PropTypes.string,
  linkDestination: PropTypes.string.isRequired,
};

export default LinkActionCardSub;
