import React from 'react';
import PropTypes from 'prop-types';
import AnimatedNumber from '../animated-number';

ByTheNumberItem.propTypes = {
    isCurrency: PropTypes.bool,
    number: PropTypes.string.isRequired,
    unit: PropTypes.string,
    description: PropTypes.string.isRequired
};

function ByTheNumberItem({ isCurrency = false, number, unit, description, ...props }) {
    return (
        <div className="container text-center">

            <div className='d-flex justify-content-center align-content-center'>
                <AnimatedNumber
                    className="numbers"
                    animateToNumber={number}
                    fontStyle={{ fontSize: 50 }}
                    configs={(number, index) => {
                        return { mass: 1, tension: 230 * (index + 1), friction: 140 };
                    }}
                    unit={unit}
                    isCurrency={isCurrency}
                ></AnimatedNumber>
            </div>
            <div className="milestone-font description text-capitalize">{description}</div>
        </div>
    );
}

export default ByTheNumberItem;