import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "../breadcrumbs";
import WelcomeSection from "../welcome";

ContactUsLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

function ContactUsLayout({ children }) {
  return (
    <WelcomeSection title="Contact Us" description="Welcome to San Bernardino County's ">
      <Breadcrumbs />
      {children}
    </WelcomeSection>
  );
}

export default ContactUsLayout;