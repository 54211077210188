import React from "react";
import PropTypes from "prop-types";
import { Box, Card } from "@mui/material";
StaticPageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

function StaticPageContainer({ title, children, ...props }) {
  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        lineHeight: "1.7",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          border: "none",
          width: { xs: "90vw", sm: "80vw", md: "70vw", xl: "1200px" },
          padding: " 35px 10px 55px",
          fontSize: { xs: "medium", sm: "large" },
        }}
      >
        <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
          <h1>{title}</h1>
        </Box>
        <br />
        {children}
      </Card>
    </div>
  );
}

export default StaticPageContainer;
