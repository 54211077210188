import React from "react";
import { TextField } from "@mui/material";

const RfiTextField = ({ formik, id, label, ...props }) => {
  return (
    <TextField
      id={id}
      label={label}
      size="small"
      value={formik.values[id]}
      onChange={formik.handleChange}
      error={formik.touched[id] && Boolean(formik.errors[id])}
      helperText={formik.touched[id] && formik.errors[id]}
    />
  );
};

export default RfiTextField;
