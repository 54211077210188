import React from "react";
import { Box, List, ListItemButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function DivisionLinks(props) {
  return (
    <Box sx={{ bgcolor: "background.paper" }} className="col-12 col-lg-8 offset-lg-2">
      <Typography variant="h5" className="px-3 px-md-0">
        More Links...
      </Typography>
      <List>
        {props.linkList.map((link, i) => (
          // TODO: lead to linkDestination
          <ListItemButton key={i} className="d-flex justify-content-between" divider>
            <>
              {link.linkTitle}
              <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
            </>
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}

export default DivisionLinks;
