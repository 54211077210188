import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Card, CardContent } from "@mui/material";

function DivisionIntro(props) {
  return (
    <Card elevation={2}>
      <CardContent
        className="d-flex justify-content-center align-items-center"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          // margin: { xs: "0 10px", md: "0 20px", lg: "0 40px", xl: "0" },
        }}
      >
        <Box
          className="col-12 col-sm-3 my-4"
          sx={{
            width: { xs: "180px", md: "200px", lg: "220px" },
            paddingLeft: { md: "20px", xl: "0px" },
            paddingRight: { xs: 0, md: "15px", lg: "30px" },
            // display: { xs: "none", md: "flex" },
          }}
        >
          <img src={props.chiefImage} className="img-fluid" alt={props.chiefName} />
        </Box>
        <Box
          className="col-12 col-sm-9"
          sx={{
            textAlign: { xs: "center", md: "left" },
            paddingLeft: { xs: 0, md: "15px", lg: "30px" },
            paddingRight: { md: "20px", xl: "0px" },
          }}
        >
          <blockquote className="blockquote">
            <Box sx={{ display: { xs: "none", lg: "block" } }}>
              <h3 style={{ overflowWrap: "anywhere", marginBottom: "25px" }}>
                Welcome to the {props.division} Division of San Bernardino County's
                Auditor/Controller/Treasurer/Tax-Collector.
              </h3>
            </Box>
            <Box sx={{ display: { xs: "block", lg: "none" } }}>
              <h3 style={{ overflowWrap: "anywhere", marginBottom: "25px" }}>
                Welcome to ATC's {props.division} Division
              </h3>
            </Box>
            <p style={{ marginBottom: "40px" }}>{props.divisionIntro}</p>
          </blockquote>

          {props.aboutLink && (
            <Button variant="contained" component={Link} to={props.aboutLink}>
              More About {props.division}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default DivisionIntro;
