import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { handleAuthenticationCallback } from "../_actions/action";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

let Callback = ({ dispatch, user }) => {
  if (user) {
    // console.log(user);
    // TODO: redirect to user profile page
    return <Navigate to="/" />;
  }
  dispatch(handleAuthenticationCallback());

  return <div className="text-center">Loading user profile...</div>;
};
Callback = connect(mapStateToProps)(Callback);

export default Callback;
