import React from 'react';
import { useEffect, useState } from "react";
import Admin from '../../components/contact-us/admin';
import Treasurer from '../../components/contact-us/treasurer';
import InternalAudit from '../../components/contact-us/internalaudit';
import { Paper, Box, Tab, Tabs } from "@mui/material";

import { getEntriesByContentType } from "../../services/contentful-service";

const contentType = "divisionContact";
function ContactUsPage(props) {
    const [selectedTab, setSelectedTab] = useState(0);

    const [adminContact, setAdminDivisionContact] = useState([]);
    const [treasurerContact, setTreasurerDivisionContact] = useState([]);
    const [internalauditContact, setInternalAuditDivisionContact] = useState([]);

    useEffect(() => {
        getData();
    }, []);

        const getData = async () => {

            const result = await getEntriesByContentType(contentType);

            const allAdminContacts = result
                .filter((item) => {

                    return item.fields.divisionGroupId === 1;
                })
                .map((item) => {
                    return {
                        divisionGroupLabel: item.fields.divisionGroupLabel,
                        streetAddress: item.fields.streetAddress,
                        city: item.fields.city,
                        state: item.fields.state,
                        zipCode: item.fields.zipCode,
                        phone1: item.fields.phone1,
                        operatingHours: item.fields.operatingHours,
                        locationImage: "https:" + item.fields.locationImage.fields.file.url,
                    };
                });
            setAdminDivisionContact(allAdminContacts);

            const allTreasurerContacts = result
                .filter((item) => {

                    return item.fields.divisionGroupId === 2;
                })
                .map((item) => {
                    return {
                        divisionGroupLabel: item.fields.divisionGroupLabel,
                        streetAddress: item.fields.streetAddress,
                        city: item.fields.city,
                        state: item.fields.state,
                        zipCode: item.fields.zipCode,
                        phone1: item.fields.phone1,
                        phone2: item.fields.phone2,
                        phone3: item.fields.phone3,
                        operatingHours: item.fields.operatingHours,
                        officeHours: item.fields.officeHours,
                        locationImage: "https:" + item.fields.locationImage.fields.file.url,
                    };
                });
            setTreasurerDivisionContact(allTreasurerContacts);

            const allInternalAuditContacts = result
                .filter((item) => {

                    return item.fields.divisionGroupId === 3;
                })
                .map((item) => {
                    return {
                        divisionGroupLabel: item.fields.divisionGroupLabel,
                        streetAddress: item.fields.streetAddress,
                        city: item.fields.city,
                        state: item.fields.state,
                        zipCode: item.fields.zipCode,
                        phone1: item.fields.phone1,
                        phone2: item.fields.phone2,
                        phone3: item.fields.phone3,
                        operatingHours: item.fields.operatingHours,
                        locationImage: "https:" + item.fields.locationImage.fields.file.url,
                    };
                });
            setInternalAuditDivisionContact(allInternalAuditContacts);


        };

    return (
        <Box style={{maxWidth:"80%"} }>
        <div className='overflow-hidden'>
            <title>
                Welcome to the San Bernardino County Auditor-Controller/Treasurer/Tax Collector
            </title>
            <meta
                name="description"
                content="This office is committed to serving our customers by processing, safeguarding, and providing information regarding the finances and public records of the County. We perform these functions with integrity, independent judgment, and outstanding service. We are accurate, timely, courteous, innovative, and efficient because of our well-trained and accountable staff. "
                key="desc"
            />

            <div style={{ marginTop: "4rem" }}>
                <Paper
                    elevation={3}
                    style={{
                        padding: "20px",
                        margin: "20px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Tabs
                        value={selectedTab}
                        onChange={(event, newValue) => setSelectedTab(newValue)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="Contact Us Tabs"
                        style={{ width: "100%" }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#003366",
                                height: "4px",
                                borderRadius: "4px",
                            },
                        }}
                    >
                        <Tab
                            label="Administration, Controller, Disbursement, Property Tax"
                            style={{
                                transition: "background-color 0.3s",
                                backgroundColor: selectedTab === 0 ? "#e0e0e0" : "transparent",
                                "&:hover": {
                                    backgroundColor: "#e0e0e0",
                                },
                            }}
                        />
                        <Tab
                                label="Treasurer, Tax Collector"
                            style={{
                                transition: "background-color 0.3s",
                                backgroundColor: selectedTab === 1 ? "#e0e0e0" : "transparent",
                                "&:hover": {
                                    backgroundColor: "#e0e0e0",
                                },
                            }}
                        />
                        <Tab
                            label="Internal Audit Division, Revenue Recovery Division, and SAP CoE Division"
                            style={{
                                transition: "background-color 0.3s",
                                backgroundColor: selectedTab === 2 ? "#e0e0e0" : "transparent",
                                "&:hover": {
                                    backgroundColor: "#e0e0e0",
                                },
                            }}
                        />

                    </Tabs>

                    <form>
                        {selectedTab === 0 && (
                            <>
                                    <div className="sec" id="admin"><Admin var1={adminContact}></Admin></div>

                            </>
                        )}
                        {selectedTab === 1 && (
                            <>
                                    <div className="sec" id="treasurer"><Treasurer var1={treasurerContact}></Treasurer></div>
                            </>
                        )}
                        {selectedTab === 2 && (
                            <>
                                    <div className="sec" id="internalaudit"><InternalAudit var1={internalauditContact}></InternalAudit></div>
                            </>
                        )}

                    </form>
                </Paper>
            </div>
            </div>
            </Box>
    )
}
export default ContactUsPage;