import React, { useEffect } from "react";
import PropTypes from "prop-types";

ScrollToTop.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

function ScrollToTop({ children, ...props }) {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      if (document.querySelector(hash)) {
        window.location.replace(window.location.hash); // scroll to hash link (even when link is pasted to a new tab or clicked from an outside source)
      } else {
        window.scrollTo(0, 0); // scroll to top of target page if hash link does not exist
      }
    } else {
      window.scrollTo(0, 0); // scroll to top of target page if link is not a hash link
    }
  });

  return <>{children}</>;
}

export default ScrollToTop;
