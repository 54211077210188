import { Card, CardContent } from "@mui/material";
import React from "react";

const ExecMemberCard = ({ name, position, imgSrc, imgHeight, children, ...props }) => {
    return (
        <>
            <Card className="exec-card shadow-lg">
                <CardContent>
                    <img src={imgSrc} width="200" height={imgHeight} alt="team member" className="rounded-circle exec-img" />
                    <div className="d-flex flex-column align-items-center">
                        <p className="h6">{name}</p>
                        <small>{position}</small></div>
                    <div className="exec-card-img-overlay">
                        <div className="content">
                            {children}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default ExecMemberCard