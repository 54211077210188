import React from "react";
import HomepageTemplate from "../../components/division-homepage/division-homepage-template";

function PropertyTaxHome(props) {
  const tileList = [
    { title: "San Bernardino County Unclaimed Property Tax Refund Website", color: "four", link: "#" },
    { title: "Reports and Publications", color: "five", link: "#" },
    { title: "RDA Dissolution Information", color: "two", link: "#" },
  ];

  return (
    <HomepageTemplate
      divisionCode="PT"
      division="Property Tax"
      chiefImage="/assets/images/team/pt-chief.png"
      chiefName="Franz Zyzz"
      divisionIntro="Franciliza Zyss has served as the ATC Chief Deputy, Property Tax since June 2023. She began her career with San Bernardino County in 2007 and joined ATC in 2008, holding several lead positions all within Property Tax prior to promoting to her current role. Franciliza received her bachelor’s degree in accounting at the Far Eastern University, Manila, Philippines. Before joining the County, she obtained over 11 years of experience overseeing grant programs, accounting functions, and regional operations as a Program Development Officer at ABS-CBN Foundation."
      // aboutLink="#"
      tileList={tileList}
      // linkList={linkList}
    />
  );
}

export default PropertyTaxHome;
