import TaxCollectorLayout from '../components/layouts/tax-collector-layout';
import NewsPublicationsLayout from '../components/layouts/news-publications-layout';
import ContactUsLayout from '../components/layouts/contact-us-layout';
import TreasurerLayout from '../components/layouts/treasurer-layout';
import About from '../pages/about';
import ExecTeam from '../pages/exec-team';
import Home from '../pages/home/home.js';
import TaxCollectorHome from '../pages/tax-collector/index';
import PayOnline from '../pages/tax-collector/pay-online';
import NewsPage from '../pages/news-publications/news-page';
import PublicationsPage from '../pages/news-publications/publications-page';
import ContactUsPage from '../pages/contact-us/contact-us-page';
import TreasurerHome from '../pages/treasurer';
import InvestmentPool from '../pages/treasurer/investment-pool';
import RFI from '../pages/treasurer/rfi.js';
import { default as TreasurerContact } from '../pages/treasurer/contact-page';
import InteractiveDashboard from '../pages/treasurer/interactive-dashboard.js'
import Error404 from '../pages/404';
import LegallyMandatedDuties from "../pages/treasurer/legally-mandated-duties.js"
import SubmitRFI from '../pages/treasurer/rfi-submit.js';
import TreasurerReports from '../pages/treasurer/treasurer-reports';
import TreasurerAbout from '../pages/treasurer/treas-about.js';
import DivisionHomepageLayout from '../components/layouts/division-homepage-layout.js';
import AdministrationHome from '../pages/administration/index.js';
import DisbursementsHome from '../pages/disbursements/index.js';
import InformationTechnologyHome from '../pages/information-technology/index.js';
import AuditorHome from '../pages/auditor/index.js';
import PropertyTaxHome from '../pages/property-tax/index.js';
import RevenueRecoveryHome from '../pages/revenue-recovery/index.js';
import SAPHome from '../pages/sap-center-of-excellence/index.js';
import Controller from '../pages/controller/index.js';
import InformationTechnologyAbout from '../pages/information-technology/it-about.js';
import Callback from '../containers/callback.js';

const routes = [
    {
        path: '*', component: <Error404 />
    },
    {
        path: '/Home', component: <Home />
    },
    {
        path: '/', component: <Home />
    },
    {
        path: '/callback', component: <Callback />
    },
    {
        path: '/tax-collector', component: <TaxCollectorLayout><TaxCollectorHome /></TaxCollectorLayout>
    },
    {
        path: '/exec-team', component: <ExecTeam />
    },
    {
        path: '/about', component: <About />
    },
    {
        path: '/treasurer', component: <TreasurerLayout><TreasurerHome></TreasurerHome></TreasurerLayout>
    },
    {
        path: '/treasurer/investment-pool', component: <TreasurerLayout><InvestmentPool /></TreasurerLayout>
    },
    {
        path: '/treasurer/rfi', component: <TreasurerLayout><RFI /></TreasurerLayout>
    },
    {
        path: '/treasurer/rfi/form', component: <TreasurerLayout> <SubmitRFI /> </TreasurerLayout>
    },
    {
        path: '/treasurer/legally-mandated-duties', component: <TreasurerLayout><LegallyMandatedDuties /></TreasurerLayout>
    },
    {
        path: '/treasurer/reports', component: <TreasurerLayout><TreasurerReports /></TreasurerLayout>
    },
    {
        path: '/treasurer/contact', component: <TreasurerLayout><TreasurerContact /></TreasurerLayout>
    },
    {
        path: '/tax-collector/pay-online', component: <TaxCollectorLayout><PayOnline></PayOnline></TaxCollectorLayout>
    },
    {
        path: '/news', component: <NewsPublicationsLayout><NewsPage></NewsPage></NewsPublicationsLayout>
    },    
    {
        path: '/publications', component: <NewsPublicationsLayout><PublicationsPage></PublicationsPage></NewsPublicationsLayout>
    },    
    {
        path: '/contact-us', component: <ContactUsLayout><ContactUsPage></ContactUsPage></ContactUsLayout>
    },    
    {
        path: '/treasurer/about', component: <TreasurerLayout><TreasurerAbout /></TreasurerLayout>
    },
    {
        path: '/administration', component: <DivisionHomepageLayout title="Administration"><AdministrationHome /></DivisionHomepageLayout>
    },
    {
        path: '/disbursements', component: <DivisionHomepageLayout title="Disbursements"><DisbursementsHome /></DivisionHomepageLayout>
    },
    {
        path: '/information-technology', component: <DivisionHomepageLayout title="Informational Technology"><InformationTechnologyHome /></DivisionHomepageLayout>
    },
    {
        path: '/information-technology-about', component: <DivisionHomepageLayout title="Informational Technology"><InformationTechnologyAbout/></DivisionHomepageLayout>
    },
    {
        path: '/auditor', component: <DivisionHomepageLayout title="Internal Audits (Auditor)"><AuditorHome /></DivisionHomepageLayout>
    },
    {
        path: '/property-tax', component: <DivisionHomepageLayout title="Property Tax"><PropertyTaxHome /></DivisionHomepageLayout>
    },
    {
        path: '/revenue-recovery', component: <DivisionHomepageLayout title="Revenue Recovery"><RevenueRecoveryHome /></DivisionHomepageLayout>
    },
    {
        path: '/sap', component: <DivisionHomepageLayout title="SAP Center of Excellence"><SAPHome /></DivisionHomepageLayout>
    },
    {
        path: '/controller', component: <DivisionHomepageLayout title="Controller"><Controller /></DivisionHomepageLayout>
    },
    {
        path: '/treasurer/interactive-dashboard', component: <TreasurerLayout><InteractiveDashboard /></TreasurerLayout>
    }
];

export default routes;