import React from "react";
import ContentSection from "../content-section";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { getEntriesByContentType } from "../../services/contentful-service";

const contentType = "newsAndPublications";
function NewsAndPublications() {
    const [news, setNews] = useState([]);
    const [publications, setPublications] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getEntriesByContentType(contentType);
        // create a map of [asset IDs, assets] for efficient lookup
        //const assetUrlMap = result.includes.Asset.reduce((acc, asset) => {
        //    acc[asset.sys.id] = asset.fields.file.url;
        //    return acc;
        //}, {});

        const allNews = result
            .filter((item) => {
                return item.fields.category === 'News';
            })
            .map((item) => {
                return {
                    title: item.fields.title,
                    sortOrder: item.fields.sortOrder,
                    creationDate: item.fields.creationDate,
                    documentPath: "https:" + item.fields.document.fields.file.url,//assetUrlMap[item.fields.document.sys.id],
                };
            })
            .sort((a, b) => a.sortOrder - b.sortOrder);
        setNews(allNews);

        const allPublications = result
            .filter((item) => {
                return item.fields.category === 'Publication';
            })
            .map((item) => {
                return {
                    title: item.fields.title,
                    sortOrder: item.fields.sortOrder,
                    creationDate: item.fields.creationDate,
                    documentPath: "https:" + item.fields.document.fields.file.url,//assetUrlMap[item.fields.document.sys.id],
                };
            })
            .sort((a, b) => a.sortOrder - b.sortOrder);
        setPublications(allPublications);

    };

    const NewsPublicationsSection = (props, index) => {
        return (
            <div className="list-group-item flex-column align-items-start">
                <div className="d-flex w-100">
                    <div>{new Date(props.item.creationDate).toLocaleDateString()}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div>{props.item.title}</div>
                    <Button className='btn' aria-label={`Read More ${props.item.title}`} component={Link} target="_blank" to={props.item.documentPath}><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></Button>
                </div>
            </div>
        );
    };

    return (
        <div className='news-publication position-relative'>
            <div className="custom-shape-divider-top-1679438911">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
                </svg>
            </div>
            <ContentSection header="News and Publications">
                <div className="container d-flex justify-content-center align-content-center mt-md-4">
                    <div className='row'>
                        <div className='col-lg-6 px-5 ps-lg-4'>
                            <div className='mt-md-5'>
                                <p className='fs-3'>In Other News...</p>
                                <div className="list-group  list-group-flush">
                                    {news.map((item, index) => (
                                        <NewsPublicationsSection key={index} item={item}></NewsPublicationsSection>
                                    )).slice(0, 6)}
                                </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <Button><a href="news" style={{ textDecoration: 'none' }}>Go to News</a></Button>
                            </div>
                        </div>
                        <div className='col-lg-6 px-5 pe-lg-4'>
                            <div className='mt-md-5'>
                                <p className='fs-3'>Publications</p>
                                <div className="list-group  list-group-flush">
                                    {publications.map((item, index) => (
                                        <NewsPublicationsSection key={index} item={item}></NewsPublicationsSection>
                                    )).slice(0, 6)}
                                </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <Button><a href="publications" style={{ textDecoration: 'none' }}>Go to Publications</a></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentSection >
            <div className="custom-shape-divider-bottom-1679439189">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
                </svg>
            </div>
        </div>
    );
}
export default NewsAndPublications;