// ./src/components/Navigation.js

import React, { useEffect } from "react";
import { Button, Avatar } from "@mui/material";
import { signIn, signOut } from "../../services/auth";

export default function ({ user }) {
  const loggedInUser = sessionStorage.getItem("user");
  const storedUser = loggedInUser ? JSON.parse(loggedInUser) : user;

  useEffect(() => {
    if (storedUser) {
      if (storedUser.expiresAt < new Date().getTime()) {
        signOut();
      }
    }
  }, []);

  return (
    <div className="d-flex align-items-center">
      {!storedUser && (
        <Button type="button" variant="Text" className="mx-3" onClick={signIn}>
          Log in
        </Button>
      )}
      {storedUser && (
        <>
          <Avatar src={storedUser.profile["https://dogfy.com/random_dog"]} className="mx-3" />
          {storedUser.profile.email}
          <Button type="button" variant="Text" className="mx-3" onClick={signOut}>
            Log out
          </Button>
        </>
      )}
    </div>
  );
}
