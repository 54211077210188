import React from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { HealthAndSafety, CurrencyExchange, Outbound } from "@mui/icons-material";
import { cardColors } from "../../utils/utils";
import StaticPageContainer from "../../components/static-page";

InvestmentPool.propTypes = {};

function InvestmentPool(props) {
  const PolicyItem = ({ align, text, icon, color }) => {
    return (
      <ListItem sx={{ borderRadius: 16, alignSelf: align, fontSize: "19px", bgcolor: color, mb: 1.5 }}>
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: "white" }}>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={text} sx={{ fontSize: "17px", color: "white" }} disableTypography />
      </ListItem>
    );
  };

  return (
    <StaticPageContainer title="Management of Investment Pool">
      <p>
        The San Bernardino County Pool consists of the pooled monies held by the County Treasurer on behalf of
        the County, school districts, community college districts, and certain special districts within the
        County. It is the policy of the Treasurer to invest public funds in a manner which will preserve the
        safety and liquidity of all investments within the County investment pool while obtaining a reasonable
        return within established investment guidelines.
      </p>

      <p>
        Accordingly, pursuant to our current <u>County Investment Policy</u>, the Treasurer’s Office is guided
        by the following principles, in order of importance:
      </p>

      <div className="d-flex justify-content-center pb-4">
        <List sx={{ display: "flex", flexDirection: "column" }}>
          <PolicyItem
            align="start"
            text="Safeguarding of investment principal"
            icon={<HealthAndSafety sx={{ color: cardColors.four }} />}
            color={cardColors.four}
          />
          <PolicyItem
            align="center"
            text="Maintenance of sufficient liquidity to meet daily cash flow requirements"
            icon={<CurrencyExchange sx={{ color: cardColors.two }} />}
            color={cardColors.two}
          />
          <PolicyItem
            align="end"
            text="Achievement of a reasonable rate of return consistent with the above objectives"
            icon={<Outbound sx={{ color: cardColors.three }} />}
            color={cardColors.three}
          />
        </List>
      </div>

      <p>
        The Treasury Pool is actively managed in accordance with the California Government Code, the
        Treasurer's Statement of Investment Policy, and internal investment guidelines. The Investment Policy
        is reviewed annually by the County's Treasury Oversight Committee and approved by the County Board of
        Supervisors.
      </p>

      <p>
        The pool pursues its objectives by investing in a diversified portfolio of high-quality securities in
        accordance with State law, which include U.S. Treasury and agency securities, commercial paper,
        certificates of deposit, and supranationals. All investments are purchased from approved issuers.
      </p>
    </StaticPageContainer>
  );
}

export default InvestmentPool;
