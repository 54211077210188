import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle, Button, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { getEntriesByContentType } from "../../services/contentful-service";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';



AlertService.propTypes = {
    divisionCode: PropTypes.string,
};

const contentType = "alert";

function AlertService({ divisionCode }, props) {
    const [alertList, setAlertList] = useState([]);
    const [isOpenList, setIsOpenList] = useState([]);

    useEffect(() => {
        getData(divisionCode);
    }, [divisionCode]);



    const getData = async (divisionCode) => {
        //debugger;
        const result = await getEntriesByContentType(contentType);

        // process data
        const dateNow = new Date();
        let startDate, endDate;

        const filteredAlerts = result
            .filter((item) => {
                startDate = new Date(item.fields.startDate.replace(/-/g, "/"));
                endDate = new Date(item.fields.endDate.replace(/-/g, "/"));
                endDate.setDate(endDate.getDate() + 1); // +1 day to include the end date

                return startDate <= dateNow && endDate > dateNow && item.fields.division.filter(d => d.fields.divisionAbbreviation.includes(divisionCode)).length>0;
            })
            .map((item) => {
                return {
                    title: item.fields.title,
                    description: documentToReactComponents(item.fields.description),
                    endDate: item.fields.endDate,
                    startDate: item.fields.startDate,
                    alertTypeCode: item.fields.alertType.fields.alertTypeCode,
                    sortOrder: item.fields.sortOrder,
                    division:item.fields.division
                };
            })
            .sort((a, b) => a.sortOrder - b.sortOrder);

        setAlertList(filteredAlerts);
        setIsOpenList(filteredAlerts.map((item) => true));
    };

    return (
        <>
            {alertList.map((alert, i) => (
                <Collapse key={i} in={isOpenList[i]}>
                    <Alert
                        className="mb-2"
                        //icon={false}
                        severity={alert.alertTypeCode}
                        sx={{ fontSize: "19px" }}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={() => {
                                    // setIsOpenList({ ...isOpenList, [i]: false });
                                    setIsOpenList([(isOpenList[i] = false), ...isOpenList].slice(1));
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        <AlertTitle sx={{ fontSize: "23px" }}>
                            {alert.title}
                        </AlertTitle>
                        {alert.description}
                    </Alert>
                </Collapse>
            ))}
            {isOpenList.length > 0 && isOpenList.every((i) => i === false) && (
                <Button
                    variant="outlined"
                    startIcon={<FeedbackIcon />}
                    onClick={() => {
                        setIsOpenList(isOpenList.map((item) => true));
                    }}
                >
                    Re-open alerts
                </Button>
            )}
        </>
    );
}

export default AlertService;
