export const ImportantLinksList = [
  {
    title: "Pay My Property Taxes",
    linkDestination: "/tax-collector/pay-online",
    cardColor: "one",
    description: "Pay your property taxes conveniently and securely using our website.",
    direction: "left",
  },
  {
    title: "Where do My Property Tax Dollars Go?",
    linkDestination: "/tax-collector/pay-online",
    cardColor: "two",
    description: "View the distribution of your Property Tax dollars at work.",
    direction: "down",
  },
  {
    title: "Unclaimed Property Tax Refunds",
    linkDestination: "/tax-collector/pay-online",
    cardColor: "three",
    description: "Own property in San Bernardino County? You could be owed a refund.",
    direction: "right",
  },
  {
    title: "Tax Sale",
    linkDestination: "/tax-collector/pay-online",
    cardColor: "four",
    description: "The next tax sale is tentatively scheduled for May 2023.",
    direction: "left",
  },
  {
    title: "GIS Property Tax Dashboard",
    linkDestination: "/tax-collector/pay-online",
    cardColor: "five",
    description: "View property tax data at the TRA and taxing agency jurisdiction level.",
    direction: "down",
  },
  {
    title: "Estimate your Taxes",
    linkDestination: "/tax-collector/pay-online",
    cardColor: "one",
    description: "Estimate your new tax bills for your property.",
    direction: "right",
  },
  {
    title: "2022 ATC Annual Report",
    linkDestination: "/tax-collector/pay-online",
    cardColor: "two",
    description: "A comprehensive Annual Report for the people of San Bernardino County.",
    direction: "left",
  },
  {
    title: "Report Fraud, Waste, & Abuse",
    linkDestination: "/tax-collector/pay-online",
    cardColor: "three",
    description: "You can make a difference by putting a stop to fraud, waste and abuse.",
    direction: "down",
  },
  {
    title: "Financial Reports",
    linkDestination: "/tax-collector/pay-online",
    cardColor: "four",
    description: "View property tax data at the TRA and taxing agency jurisdiction level.",
    direction: "right",
  },
];

{
  /* <div className="col-6 col-md-4 my-3">
              <Fade triggerOnce direction="left" duration={fadeDuration}>
                <ImportantLinksActionCard
                  title="Pay My Property Taxes"
                  linkDestination="/tax-collector/pay-online"
                  cardColor={cardColors.one}
                >
                  <small>Pay your property taxes conveniently and securely using our website.</small>
                </ImportantLinksActionCard>
              </Fade>
            </div>
            <div className="col-6 col-md-4 my-3">
              <Fade triggerOnce direction="down" duration={fadeDuration}>
                <ImportantLinksActionCard
                  title="Where do My Property Tax Dollars Go?"
                  linkDestination="/tax-collector/pay-online"
                  cardColor={cardColors.two}
                >
                  <small>View the distribution of your Property Tax dollars at work.</small>
                </ImportantLinksActionCard>
              </Fade>
            </div>
            <div className="col-6 col-md-4 my-3">
              <Fade triggerOnce direction="right" duration={fadeDuration}>
                <ImportantLinksActionCard
                  title="Unclaimed Property Tax Refunds"
                  linkDestination="/tax-collector/pay-online"
                  cardColor={cardColors.three}
                >
                  <small>Own property in San Bernardino County? You could be owed a refund.</small>
                </ImportantLinksActionCard>
              </Fade>
            </div>
            <div className="col-6 col-md-4  my-3">
              <Fade triggerOnce direction="left" duration={fadeDuration}>
                <ImportantLinksActionCard
                  title="Tax Sale"
                  linkDestination="/tax-collector/pay-online"
                  cardColor={cardColors.four}
                >
                  <small>The next tax sale is tentatively scheduled for May 2023.</small>
                </ImportantLinksActionCard>
              </Fade>
            </div>
            <div className="col-6 col-md-4 my-3">
              <Fade triggerOnce direction="down" duration={fadeDuration}>
                <ImportantLinksActionCard
                  title="GIS Property Tax Dashboard"
                  linkDestination="/tax-collector/pay-online"
                  cardColor={cardColors.five}
                >
                  <small>View property tax data at the TRA and taxing agency jurisdiction level.</small>
                </ImportantLinksActionCard>
              </Fade>
            </div>
            <div className="col-6 col-md-4 my-3">
              <Fade triggerOnce direction="right" duration={fadeDuration}>
                <ImportantLinksActionCard
                  title="Estimate your Taxes"
                  linkDestination="/tax-collector/pay-online"
                  cardColor={cardColors.one}
                >
                  <small>Estimate your new tax bills for your property.</small>
                </ImportantLinksActionCard>
              </Fade>
            </div>
            <div className="col-6 col-md-4  my-3">
              <Fade triggerOnce direction="left" duration={fadeDuration}>
                <ImportantLinksActionCard
                  title="2022 ATC Annual Report"
                  linkDestination="/tax-collector/pay-online"
                  cardColor={cardColors.two}
                >
                  <small>A comprehensive Annual Report for the people of San Bernardino County.</small>
                </ImportantLinksActionCard>
              </Fade>
            </div>
            <div className="col-6 col-md-4  my-3">
              <Fade triggerOnce direction="down" duration={fadeDuration}>
                <ImportantLinksActionCard
                  title="Report Fraud, Waste, & Abuse"
                  linkDestination="/tax-collector/pay-online"
                  cardColor={cardColors.three}
                >
                  <small>You can make a difference by putting a stop to fraud, waste and abuse.</small>
                </ImportantLinksActionCard>
              </Fade>
            </div>
            <div className="col-6 col-md-4  my-3">
              <Fade triggerOnce direction="right" duration={fadeDuration}>
                <ImportantLinksActionCard
                  title="Financial Reports"
                  linkDestination="/tax-collector/pay-online"
                  cardColor={cardColors.four}
                >
                  <small>View property tax data at the TRA and taxing agency jurisdiction level.</small>
                </ImportantLinksActionCard>
              </Fade>
            </div> */
}
