import React, { useEffect } from "react";
import PropTypes from "prop-types";
import WelcomeSection from "../welcome";
import Breadcrumbs from "../breadcrumbs";

DivisionHomepageLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

function DivisionHomepageLayout({ title, children, ...props }) {
  return (
    <WelcomeSection title={title} description="Welcome to San Bernardino County's ">
      <Breadcrumbs />
      {children}
    </WelcomeSection>
  );
}

export default DivisionHomepageLayout;
