import React from "react";
import HomepageTemplate from "../../components/division-homepage/division-homepage-template";

function AuditorHome(props) {
  const tileList = [
    { title: "Internal Audits", color: "four", link: "#" },
    { title: "Report Fraud, Waste, and Abuse", color: "five", link: "#" },
    { title: "Idependence of the Auditor Function", color: "two", link: "#" },
  ];

  const linkList = ["Auditor Link 1", "Auditor Link 2", "Auditor Link 3", "Auditor Link 4", "Auditor Link 5"];
  return (
    <HomepageTemplate
      divisionCode="AUD"
      division="Auditor"
      chiefImage="assets/images/team/auditor-chief.png"
      chiefName="Denise Mejico"
      divisionIntro="Denise Mejico has served as ATC Chief Deputy Auditor since June 2013. Denise began her career in 1999 as an Accountant with the Orange County Auditor-Controller. She joined the San Bernardino County Auditor-Controller's office in 2005 as Accounts Payable Manager. She subsequently served as Internal Audits Manager and Chief Deputy, Disbursements. Denise holds a B.S. degree in Accounting from Cal Poly Pomona."
      // aboutLink="#"
      tileList={tileList}
      // linkList={linkList}
    />
  );
}

export default AuditorHome;
