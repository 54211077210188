import React from "react";
import { Box, Button, Card } from "@mui/material";
import { Link } from "react-router-dom";

function TreasurerAbout() {
  const titleFontSize = "calc(20px + 0.6vw)";
  const subtitleFontSize = "calc(19px + 0.5vw)";
  const textFontSize = "calc(15px + 0.3vw)";

  return (
    <div
      style={{
        witdh: "100vw",
        display: "grid",
        justifyContent: "center",
        lineHeight: "1.7",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          border: "none",
          padding: { xs: "40px", sm: "35px 15vw 55px" },
          fontSize: { xs: "medium", sm: "large" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="mb-5">
          <blockquote className="blockquote" style={{ textAlign: "justify" }}>
            <h1 className="text-center mb-4" style={{ fontSize: titleFontSize, overflowWrap: "anywhere" }}>
              Welcome to the Treasurer Division (The Treasury) of San Bernardino County's
              Auditor/Controller/Treasurer/Tax-Collector.
            </h1>
            <div style={{ fontSize: textFontSize }}>
              <p>
                The Treasury acts as a fiduciary and custodian of all County funds. The Treasury provides
                banking services to all County departments, as well as voluntary and involuntary participants
                of the County's Investment Pool. In addition to banking, the Treasury is also responsible for
                management of the County's Investment Pool.
              </p>
              <p>
                The Banking and Settlement function is responsible for processing all financial transactions,
                including the clearance of checks, Automated Clearing House (ACH) transfers, and wire
                transactions for the County, school districts and special agencies. This division is also
                charged with the processing and accounting of all investment transactions.
              </p>
              <p>
                The Treasury adheres to the County Investment Policy and California Government Code Sections
                53601 & 53635, with emphasis on safety, liquidity and yield. The Treasurer's investment policy
                is revised and updated annually, reviewed by the Treasury Oversight Committee (TOC) and
                approved by the San Bernardino County Board of Supervisors. The County Investment Pool is
                rated AAAf/S1 by Fitch Ratings; which are the highest attainable safety (credit quality) and
                market sensitivity ratings.
              </p>
              <p>
                Treasury's investment approach is conservative, ensuring diversification across a range of
                assets. This includes U.S. Treasury and agency securities, commercial paper, certificates of
                deposit, supranational bonds, asset backed securities, repurchase agreements and other
                instruments. All investment decisions involve a rigorous evaluation process to confirm
                compliance with the County's investment objectives and policy guidelines.
              </p>
            </div>
          </blockquote>
        </div>

        <div className="mb-5 pb-2">
          <Card elevation={2}>
            <h3 className="text-center m-4 mb-0" style={{ fontSize: subtitleFontSize }}>
              Treasurer's Chief Deputy
            </h3>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" } }}>
              <img
                src="/assets/images/team/treasurer-chief.jpg"
                alt="Parth Bhatt"
                style={{
                  objectFit: "contain",
                  width: "max(150px + 4vw, 150px)",
                  maxWidth: "300px",
                  margin: "30px",
                }}
              />
              <blockquote className="blockquote mt-3 mb-5 mx-4" style={{ fontSize: textFontSize }}>
                <p>
                  Parth Bhatt joined the San Bernardino County Treasurer Division in 2016 and was appointed
                  Chief Deputy Treasurer in 2023. Prior to his San Bernardino County service, Parth served as
                  Portfolio Manager for Orange County and worked in the private sector as a Portfolio
                  Management Associate and an Assistant Portfolio Manager. He holds a Bachelor of Arts and a
                  Master of Arts in Economics from California State University, Fullerton, and a diploma in
                  Computer Engineering from Mumbai University.
                </p>
                <p>
                  Parth is an adjunct professor of finance at UC San Diego's Rady School of Management. He
                  teaches class (MGTF - 495) on fixed income investing for the Masters in Finance program. He
                  is an advisory board member of California State University, Fullerton's Titan Capital
                  Management program.
                </p>
              </blockquote>
            </Box>
          </Card>
        </div>

        <Button variant="contained" component={Link} to="/treasurer">
          Return to Treasurer Home
        </Button>
      </Card>
    </div>
  );
}

export default TreasurerAbout;
