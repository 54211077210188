import React from "react";
import ContentSection from "../content-section";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { getEntriesByContentType } from "../../services/contentful-service";

const contentType = "achievements";
function Achievements() {
    const [achievements, setAchievements] = useState([]);
    useEffect(() => {
          (function () {
            var elements;
            var windowHeight;
            function init() {
              elements = document.querySelectorAll(".hidden");
              windowHeight = window.innerHeight;
            }

            function checkPosition() {
              for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                var positionFromBottom = elements[i].getBoundingClientRect().bottom;

                if (positionFromBottom - windowHeight <= 0) {
                  element.classList.add("fade-in-element");
                  element.classList.remove("hidden");
                }
              }
            }

            window.addEventListener("scroll", checkPosition);
            window.addEventListener("resize", init);

            init();
            checkPosition();
        })();
        getData();
    }, []);

    const getData = async () => {
        const result = await getEntriesByContentType(contentType);
        // create a map of [asset IDs, assets] for efficient lookup
        //const assetUrlMap = result.includes.Asset.reduce((acc, asset) => {
        //    acc[asset.sys.id] = asset.fields.file.url;
        //    return acc;
        //}, {});

        const allAchievements = result
            .map((item) => {
                return {
                    title: item.fields.title,
                    description: item.fields.description,
                    sortOrder: item.fields.sortOrder,
                    imageURL: "https:" + item.fields.iconImage.fields.file.url,//assetUrlMap[item.fields.iconImage.sys.id],
                };
            })
            .sort((a, b) => a.sortOrder - b.sortOrder);
        setAchievements(allAchievements);
    };

    const AchievementsSection = (props, index) => {
        return (

                <div key={index} className="four-part-border hidden" style={{ breakInside: "avoid-column" }}>
                  <p className="w3-border shadow-lg p-4 p-md-5 bg-body rounded">
                    <img src={props.item.imageURL} className="achieve-icon" alt=""></img>
                    &nbsp;&nbsp;<b>{props.item.title}</b> {props.item.description}
                  </p>
                </div>
        );
    };

    return (
      <>
            <ContentSection header="Latest ATC Achievements">
                <div className="container">
                    <Box
                        sx={{
                            columnCount: { xs: 1, sm: 2, lg: 3 },
                            justifyContent: "center",
                            breakInside: "avoid-column",
                        }}
                    >
                {achievements.map((item, index) => (
                    <AchievementsSection key={index} item={item} />
                ))}
                    </Box>
                </div>
            </ContentSection>
        </>
    );
}
export default Achievements;