import React from "react";

const AdminDivisionContact = (props) => {

    return (
        <div className='news-publication position-relative'>
            <div className="container justify-content-center align-content-center mt-md-4">
                <div className="list-group  list-group-flush">
                    {props.var1.map((item, index) => (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                                <div className="content-wrapper" style={{ minHeight: "150px", minWidth: "500px" }} >
                                    <h3>Address:</h3>
                                    <div>{item.streetAddress}</div>
                                    <div>{item.city + ', ' + item.state + ' ' + item.zipCode}</div>
                                    <div>{item.phone1}</div>
                                </div>
                                <div className="content-wrapper">
                                    <h3>Operating Hours:</h3>
                                    <div>{item.operatingHours}</div>
                                </div>
                            </div>
                            <div>
                                <img src={item.locationImage} alt=""></img>
                            </div>
                        </div>

                    ))}
                    <br /><br /><br /><br /><br />

                </div>
            </div>
            <div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.1153994877436!2d-117.29152782283867!3d34.06655591697222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcad8248e6ba13%3A0x98750ba7c33ecac6!2sSan%20Bernardino%20County%20ATC!5e0!3m2!1sen!2sus!4v1681325129103!5m2!1sen!2sus"
                    width="100%"
                    height="320"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    title="ATC Video"
                ></iframe>
                <br /><br /><br /><br /><br />
            </div>

            <div className="custom-shape-divider-bottom-1679439189">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
                </svg>
            </div>

        </div>
    );
}
export default AdminDivisionContact;


//export default GoogleApiWrapper({
//    apiKey: ('AIzaSyAw5OGodm5XdLG-Ez5k9zKFj4Ms3nSuWFo')
//});