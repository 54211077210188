import React from "react";
import LinkActionCard from "../action-card/link-action-card";
import { cardColors } from "../../utils/utils";

function DivisionNavTiles(props) {
  const colNum =
    props.tileList.length === 1
      ? "grid-style-1"
      : props.tileList.length === 2
      ? "grid-style-2"
      : props.tileList.length === 3
      ? "grid-style-3"
      : "grid-style-4";

  return (
    <div className={`grid-container ${colNum}`}>
      {props.tileList.map((tile, i) => (
        <div key={i}>
          <LinkActionCard
            title={tile.title}
            linkDestination={tile.link}
            cardColor={cardColors[tile.color]}
          ></LinkActionCard>
        </div>
      ))}
    </div>
  );
}

export default DivisionNavTiles;
