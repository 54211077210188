import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, TextField } from "@mui/material";
let _ = require("lodash");

Search.propTypes = {
  searchIconColor: PropTypes.string,
};

const options = [
  "Pay my taxes online",
  "Pay my taxes in person",
  "Unclaimed property tax refunds",
  "Am I owed a refund?",
  "Tax Sale",
  "When is the next Tax Sale",
  "New homeowner information",
  "Contact Information",
];

function Search({ searchIconColor, placeHolder, iconSize, inputSize, ...props }) {
  const [results, setResults] = useState([]);

  const handleSearch = (e, value) => {
    console.log(e);
    if (e.type === "change") {
      return new Promise((resolve) => {
        let res = _.filter(options, (a) => {
          return a.toLowerCase().includes(value.toLowerCase().trim());
        });
        resolve(res);
      }).then((res) => setResults(res));
    } else {
      return true;
    }
  };

  return (
    <div className="container row justify-content-center px-0 px-sm-2">
      <div className="col-12 col-md-6 col-lg-4 px-0 px-sm-2">
        <div className="input-group mb-3">
          <Autocomplete
            freeSolo
            id="search-typeahead"
            className="flex-grow-1"
            placeholder={placeHolder || "What can we help you find today?"}
            options={results}
            onInputChange={handleSearch}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                }}
              />
            )}
          />
          <button className="btn btn-secondary" type="button" aria-label="Search button">
            <FontAwesomeIcon
              icon={faSearch}
              size={iconSize || "sm"}
              color={searchIconColor || "white"}
              className="ms-1"
            ></FontAwesomeIcon>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Search;
