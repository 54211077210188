import Navigation from "../nav-bar/navigation";
import Footer from "../footer";
import { useEffect } from "react";

function Layout({ children }) {
  useEffect(() => {
    document.title = "Auditor-Controller/Treasurer/Tax Collector";
  }, []);

  return (
    <>
      <div className="main">
        <Navigation />
        {children}
        <Footer></Footer>
      </div>
    </>
  );
}

export default Layout;
