import React from 'react';
import Typography from '@mui/material/Typography';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const WelcomeSection = ({ title, description, children, ...props }) => {
    return (
        <>
            <div className="welcome-div">
                <div className='welcome-overlay d-flex flex-column justify-content-center text-center align-items-sm-start'>
                    <ThemeProvider theme={theme}>
                        <Typography className="px-3 ps-sm-5" variant="h6">{description}</Typography>
                        <Typography className="px-3 ps-sm-5" variant="h2">{title}</Typography>
                    </ThemeProvider>
                </div>
            </div>
            <div>
                {children}
            </div>
        </>
    )
}

export default WelcomeSection







