import React, { useEffect, useState } from 'react'
import "../icons";
import { ParallaxProvider } from "react-scroll-parallax";
import { Fade } from "react-awesome-reveal";
import ByTheNumberItem from './by-the-number-item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntriesByContentType } from "../../services/contentful-service";

const fadeDuration = 1500;

const contentType = "accomplishment";

const ByTheNumbers = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getEntriesByContentType(contentType);

        setData(result.sort((a, b) => a.fields.sortOrder - b.fields.sortOrder));

    };

    return (
        <ParallaxProvider>
            <div className="parallax paralsec by-the-numbers d-flex justify-content-center align-items-center">
                <div className="bg-overlay">
                    <div>
                        <Fade triggerOnce direction="left" duration={fadeDuration}>
                            <div className="row row-centered fluid mb-4">
                                <div className='d-flex justify-content-center mt-5 mb-4'>
                                    <p className='display-6 text-center title header p-0'>Accomplishments</p>
                                </div>
                            </div>
                        </Fade>

                        <Fade triggerOnce direction="up" duration={fadeDuration}>
                            {data &&
                                <div className="row row-centered fluid justify-content-center container">
                                    {data.map((item, index) => (
                                        <div key={index} className="my-3 col-xs-6 col-lg-3 col-xl-2 col-xxl-3  mt-5">
                                            <div className="container text-center">
                                                <ByTheNumberItem isCurrency={item.fields.isCurrency} number={item.fields.quantity.toString()} unit={item.fields.unit} description={item.fields.description} icon={<FontAwesomeIcon size='2x' ></FontAwesomeIcon>}></ByTheNumberItem>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            }
                        </Fade>
                    </div>
                </div>
            </div>
        </ParallaxProvider >
    )
}

export default ByTheNumbers