import React from 'react';
import { Fade } from "react-awesome-reveal";

function Bio({ imgPositionLeft, pageTitle, title, subTitle, imgSrc, description, imgClass, imgHeight, imgWidth, ...props }) {
    let fadeDirection = imgPositionLeft ? "left" : "right";
    let imageOrder = imgPositionLeft ? "order-xl-1" : "order-xl-2";
    let descriptionOrder = imgPositionLeft ? "order-xl-2" : "order-xl-1";
    return <div className='d-flex justify-content-center align-items-center content'>
        <div className="col-lg-11 col-sm-12">
            <div className="py-3 py-lg-4">
                <Fade triggerOnce direction={fadeDirection} >
                    <div className="row justify-content-center">
                        <div className={`col-md-auto order-first ${imageOrder} d-md-block d-flex justify-content-center py-4`}>
                            <img src={imgSrc} width={imgWidth} height={imgHeight} alt="Division Chief" className={imgClass} />
                        </div>
                        <div className={`col-12 col-md-8 px-4 py-3 order-last ${descriptionOrder}`}>
                            <h3>{title}</h3>
                            <h5>{subTitle}</h5>
                            <p style={{ fontSize: "17px" }}>{description}</p>
                        </div>
                    </div>
                </Fade>
            </div>
            <br />
        </div>
    </div>
}

export default Bio;
