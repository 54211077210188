import auth0 from "auth0-js";

const auth0Client = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  clientID: process.env.REACT_APP_AUTH0_CLIENTID,
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECTURI,
  responseType: process.env.REACT_APP_AUTH0_RESPONSETYPE,
  scope: process.env.REACT_APP_AUTH0_SCOPE,
});

export function handleAuthentication() {
  return new Promise((resolve, reject) => {
    auth0Client.parseHash((err, authResult) => {
      console.log("parseHash");
      if (err) {
        console.log("error occurred");
        console.log(err);
        return reject(err);
      }
      if (!authResult || !authResult.idToken) {
        console.log("error #2 occurred");
        console.log(err);
        return reject(err);
      }

      // const expiresAt = authResult.idTokenPayload.exp * 1000;
      const expiresAt = new Date().getTime() + 60 * 1000; // expires in 1 hour
      const profile = {
        name: authResult.idTokenPayload.name,
        email: authResult.idTokenPayload.email,
        picture: authResult.idTokenPayload.picture,
        "https://dogfy.com/random_dog": authResult.idTokenPayload["https://dogfy.com/random_dog"],
      };
      const user = {
        authenticated: true,
        profile,
        expiresAt,
      };

      sessionStorage.setItem("user", JSON.stringify(user));
      console.log("resolved");
      resolve(user);
    });
  });
}

export function signIn() {
  auth0Client.authorize();
}

export function signOut() {
  sessionStorage.clear();
  auth0Client.logout({
    returnTo: process.env.REACT_APP_AUTH0_LOGOUTURI,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID,
  });
}
