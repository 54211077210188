import React from "react";
import DivisionIntro from "./division-intro";
import DivisionNavTiles from "./division-nav-tiles";
import DivisionLinks from "./division-links";
import AlertService from "../alert-service/alert-service";

function DivisionHomepageTemplate(props) {
  return (
    <div className="container mt-md-4">
      {/* alerts */}
      <div className="mb-4">
        <AlertService divisionCode={props.divisionCode}></AlertService>
      </div>
      {/* intro */}
      <div className="row">
        <div className="col my-2 pb-5">
          <DivisionIntro
            division={props.division}
            chiefName={props.chiefName}
            chiefImage={props.chiefImage}
            divisionIntro={props.divisionIntro}
            aboutLink={props.aboutLink}
          />
        </div>
      </div>

      {/* navigation tiles */}
      {props.tileList && (
        <div className="row">
          <div className="col my-2 pb-5">
            <DivisionNavTiles tileList={props.tileList} />
          </div>
        </div>
      )}

      {/* more links */}
      {props.linkList && (
        <div className="row">
          <div className="col my-2 pb-5">
            <DivisionLinks linkList={props.linkList} />
          </div>
        </div>
      )}

      {/* additional components */}
      {props.additionalComponents && props.additionalComponents}
    </div>
  );
}

export default DivisionHomepageTemplate;
