import React from "react";
import HomepageTemplate from "../../components/division-homepage/division-homepage-template";
import BasicWhiteCard from "../../components/basic-white-card";

function RevenueRecoveryHome(props) {
  const tileList = [
    { title: "Revenue Recovery Nav Tile 1", color: "four", link: "#" },
    { title: "Revenue Recovery Nav Tile 2", color: "five", link: "#" },
    { title: "Revenue Recovery Nav Tile 3", color: "one", link: "#" },
    { title: "Revenue Recovery Nav Tile 4", color: "two", link: "#" },
    { title: "Revenue Recovery Nav Tile 5", color: "two", link: "#" },
    { title: "Revenue Recovery Nav Tile 6", color: "three", link: "#" },
    { title: "Revenue Recovery Nav Tile 7", color: "five", link: "#" },
  ];

  return (
    <HomepageTemplate
      divisionCode="RVR"
      division="Revenue Recovery"
      chiefImage="assets/images/team/rr-chief.png"
      chiefName="Sonia Hermosillo"
      divisionIntro="Sonia Hermosillo has been with the County of San Bernardino since 2000 and has served the Auditor-Controller/Treasurer/Tax Collector in a number of management capacities. She is currently Division Chief of Revenue Recovery overseeing the revenue enhancement operation that serves Tax Collector, ARMC, Superior Courts and other county departments. Sonia’s professional career has spanned over 30 years with experience in external auditing, tax preparation and Healthcare Finance. She is a graduate of Cal Poly Pomona, and holds a Bachelor’s Degree in Business Administration with a major in Accounting."
      // aboutLink="#"
      // tileList={tileList}
      // linkList={linkList}
      additionalComponents={
        <BasicWhiteCard
          content={
            <>
              <p>
                As a division of the Auditor-Controller/Treasurer/Tax Collector, Revenue Recovery provides
                debt collection services to County agencies. Through the use of innovative technology, our
                highly skilled staff provides quality service to ensure maximum recovery of debt owed to the
                County.
              </p>
              <p>Click here to visit Revenue Recovery website.</p>
            </>
          }
        />
      }
    />
  );
}

export default RevenueRecoveryHome;
