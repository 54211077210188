import React from "react";
import HomepageTemplate from "../../components/division-homepage/division-homepage-template";

function ControllerHome(props) {
  const tileList = [
    { title: "General Accounting", color: "four", link: "#" },
    { title: "Management Services", color: "five", link: "#" },
    { title: "Pay Your Taxes Online", color: "two", link: "#" },
  ];

  const linkList = [
    { linkTitle: "Controller Link 1", linkDestination: "#" },
    { linkTitle: "Controller Link 2", linkDestination: "#" },
    { linkTitle: "Controller Link 3", linkDestination: "#" },
    { linkTitle: "Controller Link 4", linkDestination: "#" },
    { linkTitle: "Controller Link 5", linkDestination: "#" },
  ];
  return (
    <HomepageTemplate
      divisionCode="CTL"
      division="Controller"
      chiefImage="assets/images/team/controller-chief.png"
      chiefName="Vanessa Doyle"
      divisionIntro="Vanessa Doyle has served as ATC Chief Deputy Controller since March 2018. She began her career in San Bernardino County as an Accountant in the Recorder Division in 2005. She promoted through the ranks, serving in various capacities in Accounts Payable, Internal Audits and Reimbursable Projects. She promoted to Manager, Property Tax Division and then in June 2013 to Chief Deputy, Disbursements. She subsequently served as Chief Deputy Tax Collector, Chief Deputy Revenue Recovery Division and Chief Deputy, Property Tax. She holds a B.S. in Business Administration with a concentration in Accounting from Cal State University, San Bernardino."
      // aboutLink="#"
      tileList={tileList}
      linkList={linkList}
    />
  );
}

export default ControllerHome;
