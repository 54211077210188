export const onlineServiceItemsList = [
  {
    items: [
      { item: "Get Electronic Tax Bills", itemLink: "#" },
      { item: "Online Documents", itemLink: "#" },
      { item: "Pay your Taxes Online", itemLink: "#" },
      { item: "Unclaimed Property Tax Refund", itemLink: "#" },
      { item: "Frequently Asked Questions", itemLink: "#" },
    ],
  },
  {
    subheader: "Useful Links",
    items: [
      { item: "San Bernardino County", itemLink: "#" },
      { item: "Customer Feedback", itemLink: "#" },
      { item: "Assessor Recorder Clerk", itemLink: "#" },
      { item: "District Attorney", itemLink: "#" },
      { item: "Sheriff", itemLink: "#" },
      { item: "Report Fraud & Waste", itemLink: "#" },
      { item: "Board of Supervisors", itemLink: "#" },
    ],
  },
];
