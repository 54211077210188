import React from "react";
import { Box, Button, Card, CardContent } from "@mui/material";
import "./treasurer.scss";
import { Link } from "react-router-dom";

const ChiefMessage = () => {
  return (
    <div className="col my-2 pb-5">
      <Card elevation={2}>
        <CardContent
          className="d-flex justify-content-center align-items-center"
          sx={{
            flexDirection: { xs: "column", md: "row" },
            margin: { xs: "0 10px", md: "0 20px", lg: "0 70px" },
          }}
        >
          <Box
            className="my-4"
            sx={{
              maxWidth: { xs: "180px", md: "200px", lg: "220px" },
              marginRight: { xs: 0, md: "40px", lg: "60px" },
              display: { xs: "none", md: "flex" },
            }}
          >
            <img src="/assets/images/team/treasurer-chief.jpg" className="img-fluid" alt="Parth Bhatt" />
          </Box>
          <Box
            sx={{
              textAlign: { xs: "center", md: "left" },
              margin: { xs: 0, md: "10px", lg: "20px" },
            }}
          >
            <blockquote className="blockquote">
              <Box sx={{ display: { xs: "none", lg: "block" } }}>
                <h3 style={{ overflowWrap: "anywhere", marginBottom: "25px" }}>
                  Welcome to the Treasurer Division (The Treasury) of San Bernardino County's
                  Auditor/Controller/Treasurer/Tax-Collector.
                </h3>
              </Box>
              <Box sx={{ display: { xs: "block", lg: "none" } }}>
                <h3 style={{ overflowWrap: "anywhere", marginBottom: "25px" }}>
                  Welcome to ATC's Treasurer Division
                </h3>
              </Box>
              <p style={{ marginBottom: "40px" }}>
                The Treasury acts as a fiduciary and custodian of all County funds. The Treasury provides
                banking services to all County departments, as well as voluntary and involuntary participants
                of the County's Investment Pool. In addition to banking, the Treasury is also responsible for
                management of the County's Investment Pool.
              </p>
            </blockquote>
            <Button variant="contained" component={Link} to="/treasurer/about">
              More About Treasurer
            </Button>
          </Box>

          {/* <figcaption className="blockquote-footer mt-1">
              <cite title="Source Title">Parth Bhatt</cite>
            </figcaption> */}
        </CardContent>
      </Card>
    </div>
  );
};

export default ChiefMessage;
