import { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { getEntriesByContentType } from "../../services/contentful-service";

const speed = 10000;
const contentType = "homepageBanner";

function Alerts() {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getEntriesByContentType(contentType);
    // process data
    const dateNow = new Date();
    let startDate, endDate;

    // create a map of [asset IDs, assets] for efficient lookup
    //const assetUrlMap = result.includes.Asset.reduce((acc, asset) => {
    //  acc[asset.sys.id] = asset.fields.file.url;
    //  return acc;
    //}, {});

    const filteredAlerts = result
      .filter((item) => {
        startDate = new Date(item.fields.startDate.replace(/-/g, "/"));
        endDate = new Date(item.fields.endDate.replace(/-/g, "/"));
        endDate.setDate(endDate.getDate() + 1); // +1 day to include the end date
        return startDate <= dateNow && endDate > dateNow;
      })
      .map((item) => {
        return {
          title: item.fields.title,
            imageURL: "https:" + item.fields.bannerImage.fields.file.url, //assetUrlMap[item.fields.bannerImage.sys.id],
          endDate: item.fields.endDate,
          startDate: item.fields.startDate,
          priority: item.fields.priority,
          link: item.fields.link,
        };
      })
      .sort((a, b) => a.priority - b.priority);

    setAlerts(filteredAlerts);
  };

  const BannerImage = (props) => {
    return (
      <div interval={speed}>
            <a href={props.item.link} target="_blank" rel="noreferrer" title={props.item.link}>
          <img className="card-img" src={props.item.imageURL} alt={props.item.title} />
        </a>
      </div>
    );
  };

  return (
    <div className="alerts-section">
      <Carousel>
        {alerts.map((item, i) => (
          <BannerImage key={i} item={item} />
        ))}
      </Carousel>
    </div>
  );
}
export default Alerts;
