import "./styles/styles.scss";
import { ThemeProvider, createTheme } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Layout from "./components/layouts/main";
import AppRoutes from "./_routes/app-routes";
import ScrollToTop from "./components/scroll-to-top";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003366",
    },
    secondary: {
      main: "#cd9700",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ParallaxProvider>
          <Layout>
            <Routes>
              {AppRoutes.map((route) => (
                <Route
                  path={route.path}
                  element={<ScrollToTop>{route.component}</ScrollToTop>}
                  key={route.path}
                />
              ))}
            </Routes>
          </Layout>
        </ParallaxProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
