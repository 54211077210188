import React from "react";
import TreasurerActionCard from "../action-card/link-action-card";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { cardColors } from "../../utils/utils";
import { faContactBook, faContactCard, faLegal, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Link } from "react-router-dom";
import "./treasurer.scss";

const NavTiles = () => {
  return (
    <>
      <div className="col-xl-3 my-2 pb-5 order-xl-2">
        <Card elevation={1}>
          <CardContent className="justify-content-center align-items-center">
            <Box
              className="hours-box"
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <Typography className="hours-text pb-2" style={{ fontWeight: "bold", textAlign: "center" }}>
                Hours of Operation
              </Typography>
              <Box style={{ display: "flex" }}>
                <div className="text-start" style={{ width: "35%" }}>
                  <Typography className="hours-text">Mon-Fri</Typography>
                </div>
                <div className="text-end" style={{ width: "65%" }}>
                  <Typography className="hours-text">5am-2pm PST</Typography>
                  <Typography className="hours-text">8am-5pm EST</Typography>
                </div>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </div>
      <div className="col-xl-9 my-2 pb-5 order-xl-1">
        <div className="grid-container grid-style-3">
          <TreasurerActionCard
            title="Fitch Rating Reports"
            linkDestination="#"
            cardColor={cardColors.four}
          ></TreasurerActionCard>
          <TreasurerActionCard
            title="Pool Report"
            linkDestination="#"
            cardColor={cardColors.five}
          ></TreasurerActionCard>
          <TreasurerActionCard
            title="ACH Wire Info"
            linkDestination="#"
            cardColor={cardColors.two}
          ></TreasurerActionCard>
          <TreasurerActionCard
            title="Broker-Dealer Application"
            linkDestination="/treasurer/rfi"
            cardColor={cardColors.one}
          ></TreasurerActionCard>
          <TreasurerActionCard
            title="Rating Agency Reports"
            linkDestination="#"
            cardColor={cardColors.three}
          ></TreasurerActionCard>
          <TreasurerActionCard
            title="Dashboard "
            linkDestination="/treasurer/interactive-dashboard"
            cardColor={cardColors.five}
          ></TreasurerActionCard>
        </div>
      </div>
      <div className="my-2 pb-5 order-xl-3">
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              More Services
            </ListSubheader>
          }
        >
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/treasurer/investment-pool">
              <ListItemIcon>
                <FontAwesomeIcon icon={faMoneyBillTrendUp} />
              </ListItemIcon>
              <ListItemText primary="Management of Investment Pool" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/treasurer/legally-mandated-duties">
              <ListItemIcon>
                <FontAwesomeIcon icon={faLegal} />
              </ListItemIcon>
              <ListItemText primary="Legally Mandated Duties" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/treasurer/contact">
              <ListItemIcon>
                <FontAwesomeIcon icon={faContactCard} />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default NavTiles;
