import React from "react";

const InternalAuditDivisionContact = (props) => {

    return (
        <div className='news-publication position-relative'>
            <div className="container justify-content-center align-content-center mt-md-4">
                <div className="list-group  list-group-flush">
                    {props.var1.map((item, index) => (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                                <div className="content-wrapper" style={{ minHeight: "200px", minWidth: "500px" }} >
                                    <h3>Address:</h3>
                                    <div>{item.streetAddress}</div>
                                    <div>{item.city + ', ' + item.state + ' ' + item.zipCode}</div>
                                    <div>{item.phone1}</div>
                                    <div>{item.phone2}</div>
                                    <div>{item.phone3}</div>
                                </div>
                                <div className="content-wrapper">
                                    <h3>Operating Hours:</h3>
                                    <div>{item.operatingHours}</div>
                                </div>
                            </div>
                            <div>
                                <img src={item.locationImage} alt=""></img>
                            </div>
                        </div>

                    ))}
                    <br /><br /><br /><br /><br />
                </div>
            </div>
            <div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.118119961089!2d-117.2915475227213!3d34.06648617315186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcac92ee652cdd%3A0x911229511613a154!2s268%20W%20Hospitality%20Ln%202nd%20Floor%2C%20San%20Bernardino%2C%20CA%2092408!5e0!3m2!1sen!2sus!4v1712172469186!5m2!1sen!2sus"
                    width="100%"
                    height="320"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    title="ATC Video"
                ></iframe>
                <br /><br /><br /><br /><br />
            </div>
            <div className="custom-shape-divider-bottom-1679439189">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
                </svg>
            </div>

        </div>
    );
}
export default InternalAuditDivisionContact;