import React from "react";
import { Fade } from "react-awesome-reveal";
import Search from "../search";
import { useParallax } from "react-scroll-parallax";
import ImportantLinksActionCard from "../action-card/link-action-card";
import { cardColors } from "../../utils/utils";
import { ImportantLinksList } from "../../utils/important-links-items";
import { Grid } from "@mui/material";

const fadeDuration = 1500;

const ImportantLinks = () => {
  const header = useParallax({ scale: [1.15, 1.25, "easeInQuad"] });
  const search = useParallax({ scale: [1, 1.15, "easeInQuad"] });

  return (
    <div className="important-links">
      <div className="display-6 header pt-2 pb-5 text-center" ref={header.ref}>
        How may we serve you?
      </div>
      <div className="d-flex justify-content-center pt-2 pb-5" ref={search.ref}>
        <Search inputSize="lg" iconSize="1x" />
      </div>
      <div className="container grid-container grid-style-3">
        {ImportantLinksList.map((item, i) => (
          <div key={i}>
            <Fade className="h-100" triggerOnce direction={item.direction} duration={fadeDuration}>
              <ImportantLinksActionCard
                title={item.title}
                linkDestination={item.linkDestination}
                cardColor={cardColors[item.cardColor]}
              >
                {item.description}
              </ImportantLinksActionCard>
            </Fade>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImportantLinks;
