import React from "react";
import Bio from "../../components/bio/bio";
import AlertService from "../../components/alert-service/alert-service";

function AdministrationHome(props) {
    return (

        <div>
            {/* alerts */}
            <div className="container mb-4">
                <div className="mb-4">
                    <AlertService divisionCode="ADM"></AlertService>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center content">
                <div className="col-9">
                    <h1 align="center" className="pb-3">Administration</h1>

                    <div className="LeftBioSection">
                        <Bio
                            imgClass="shadow-lg rounded-circle"
                            imgPositionLeft={true}
                            subTitle="Auditor-Controller/Treasurer/Tax Collector"
                            imgSrc="/assets/images/team/em.png"
                            title="Ensen Mason CPA, CFA"
                            description="San Bernardino County Auditor-Controller/Treasurer/Tax Collector (ATC) Ensen Mason was elected in June 2018 after a 30-year career as a local Certified Public Accountant (CPA) and Investment Adviser. He has prepared over 10,000 individual and business income tax returns and has 15 years of experience in financial planning and investment management. Mr. Mason is both a CPA and a Chartered Financial Analyst (CFA). He is an Enrolled Agent with the Internal Revenue Service and holds a Master’s Degree in Business Administration (MBA), with an emphasis in finance from the University of Redlands."
                        ></Bio>
                    </div>
                    <div className="RightBio">
                        <Bio
                            imgClass="rounded-circle"
                            imgPositionLeft={false}
                            subTitle=" Auditor-Controller/Treasurer/Tax Collector"
                            imgSrc="/assets/images/team/asst-atc-1.png"
                            title="John Johnson"
                            description="John Johnson joined the San Bernardino County Treasury Division in 2004 and was appointed Chief Deputy Treasurer in 2008. He is also serving as Assistant ATC. Prior to his County service, Mr. Johnson spent 15 years in the private sector as a registered securities representative. He holds a B.S. degree in Business Administration from the University of Southern Mississippi and is an active member of the California Association of County Treasurers and Tax Collectors, California Municipal Treasurers Association and the Investment Managers of Public Agencies Council."
                        ></Bio>
                    </div>
                    <div className="LeftBioSection">
                        <Bio
                            imgClass="rounded-circle"
                            imgPositionLeft={true}
                            subTitle="Auditor-Controller/Treasurer/Tax Collector"
                            imgSrc="/assets/images/team/asst-atc-2.jpeg"
                            title="Tori Roberts, CPA"
                            description="Ms. Roberts is a California-licensed CPA with almost nine years of experience in public accounting. Most recently, she was an Assurance Manager with Crowe LLP, where she served clients in a wide array of industries, including state and local government agencies, transportation agencies, joint powers authorities, special districts, and not-for-profit organizations.
                                   Ms. Roberts possesses extensive experience researching and consulting on technical matters pertaining to Uniform Guidance, accounting and financial reporting matters for GASB and FASB, contract compliance, and other emerging accounting and financial reporting matters. She has managed and supervised large and complex projects involving financial statement audits, federal compliance audits, and attestation engagements for multiple governments throughout California and Nevada.
                                   Ms. Roberts holds a Bachelor of Science in Business Administration with a concentration in Accounting from California State University, San Bernardino."
                        ></Bio>
                    </div>
                    <hr></hr>

                    <p className="lead pt-2 pb-5">
                        The Administrative Division supports all sections of the department and provides guidance and
                        assistance to the department and the public. Responsibilities include providing support to the
                        Auditor-Controller/Treasurer/Tax Collector and the Assistant Auditor-Controller/Treasurer/Tax
                        Collectors, managing personnel and oversight duties, preparing and administering the department
                        budget, and processing the department’s payroll and payables. The Administrative Division processes
                        printing requests, manages the ordering of supplies, issuance of security cards and oversees
                        building maintenance and repairs. Mandated training for department employees is scheduled and
                        tracked to meet the County’s requirements.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AdministrationHome;
