import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { CardContent, CardMedia, Typography, Box, Avatar } from "@mui/material";
import { IconContext } from "react-icons";

export default function InformationalTiles({ title, side, information, icon, bgColor }) {
  const isScreenSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isScreenXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: side === "left" ? "row" : "row-reverse",
        paddingBottom: isScreenSmall ? 5 : "60px",
      }}
    >
      <Box
        sx={{
          paddingRight: !isScreenSmall && side === "left" ? "60px" : 0,
          paddingLeft: !isScreenSmall && side === "right" ? "60px" : 0,
        }}
      >
        <div className="d-flex flex-row align-items-center mb-3">
          {isScreenSmall && (
            <Box
              sx={{
                display: "flex",
                backgroundColor: bgColor,
                padding: 1.7,
                borderRadius: "50%",
                marginRight: 2.5,
              }}
            >
              <IconContext.Provider value={{ color: "white", size: isScreenXs ? 30 : 45 }}>
                {icon}
              </IconContext.Provider>
            </Box>
          )}
          <h2 style={{ margin: 0 }}>{title}</h2>
        </div>

        {information}
      </Box>

      {!isScreenSmall && (
        <Box
          sx={{
            display: "flex",
            backgroundColor: bgColor,
            padding: 4,
            borderRadius: "50%",
          }}
        >
          <IconContext.Provider value={{ color: "white", size: 120 }}>{icon}</IconContext.Provider>
        </Box>
      )}
    </Box>
  );
}
