import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "../breadcrumbs";
import WelcomeSection from "../welcome";

TaxCollectorLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

function TaxCollectorLayout({ children }) {
  return (
    <WelcomeSection title="Tax Collector" description="Welcome to San Bernardino County's ">
      <Breadcrumbs />
      {children}
    </WelcomeSection>
  );
}

export default TaxCollectorLayout;
