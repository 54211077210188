import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Search from '../components/search';
import { Link } from 'react-router-dom/dist';
import Box from '@mui/material/Box';

function Error404() {

    return (
        <Box sx={{ height: '80vh' }} className="d-flex justify-content-center align-items-center">
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item>
                    <Box sx={{ height: '200px', mb: 2 }}>
                        <img src="/assets/logos/sb-logo-blue.png" alt="logo" className="img-fluid h-100" />
                    </Box>
                </Grid>
                <Grid item>
                    <Typography variant="h4" component="h4" gutterBottom>
                        Sorry,
                    </Typography>
                    <Typography variant="h5" component="h5" gutterBottom>
                        The page you are looking for cannot be found or does not exist.
                    </Typography>
                    {/* 
                    <Search /> */}
                </Grid>
                <Grid item>
                    <Button variant='outlined' component={Link} to="/">go Home</Button>
                </Grid>
            </Grid>
        </Box >
    );
}

export default Error404;
