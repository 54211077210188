export const divisionItemsList = [
  {
    subheader: "Administration",
    subheaderLink: "/administration",
    items: [],
  },
  {
    subheader: "Treasurer",
    subheaderLink: "/treasurer",
    items: [
      { item: "Management of Investment Pool", itemLink: "/treasurer/investment-pool" },
      { item: "RFI", itemLink: "/treasurer/rfi" },
      { item: "Rating Agency", itemLink: "#" },
      { item: "Legally Mandated Duties", itemLink: "/treasurer/legally-mandated-duties" },
    ],
  },
  {
    subheader: "Informational Technology",
    subheaderLink: "/information-technology",
    items: [],
  },
  {
    subheader: "Disbursements",
    subheaderLink: "/disbursements",
    items: [
      { item: "Account Payable", itemLink: "#" },
      { item: "Central Payroll", itemLink: "#" },
    ],
  },
  {
    subheader: "Auditor",
    subheaderLink: "/auditor",
    items: [
      { item: "Report Fraud, Waste, and Abuse", itemLink: "#" },
      { item: "Internal Audits", itemLink: "#" },
      { item: "Independence of the Auditor Function", itemLink: "#" },
    ],
  },
  { subheader: "Revenue Recovery", subheaderLink: "/revenue-recovery", items: [] },
  {
    subheader: "SAP Center of Excellence",
    subheaderLink: "/sap",
    items: [],
  },
  {
    subheader: "Controller",
    subheaderLink: "/controller",
    items: [
      { item: "General Accounting", itemLink: "#" },
      { item: "Management Services", itemLink: "#" },
      { item: "Pay Your Taxes Online", itemLink: "#" },
    ],
  },
];
