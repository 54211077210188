import React from "react";
import HomepageTemplate from "../../components/division-homepage/division-homepage-template";

function InformationTechnologyHome(props) {
  const tileList = [
    { title: "Information Technology Nav Tile 1", color: "four", link: "#" },
    { title: "Information Technology Nav Tile 2", color: "five", link: "#" },
    { title: "Information Technology Nav Tile 3", color: "one", link: "#" },
    { title: "Information Technology Nav Tile 4", color: "two", link: "#" },
    { title: "Information Technology Nav Tile 5", color: "two", link: "#" },
    { title: "Information Technology Nav Tile 6", color: "three", link: "#" },
    { title: "Information Technology Nav Tile 7", color: "five", link: "#" },
  ];

  return (
    <HomepageTemplate
      divisionCode="ITD"
      division="Information Technology"
      chiefImage="assets/images/team/it-chief.png"
      chiefName="Don Le"
      divisionIntro="The Information Technology Division provides technical leadership and support for all divisions within the Auditor-Controller/Treasurer/Tax Collector operations. The primary goal of the IT Division is to provide exceptional customer service to all users within the Department and to ensure that they have the tools to perform their jobs in an efficient and productive manner."
      aboutLink="/information-technology-about"
      // tileList={tileList}
      // linkList={linkList}
    />
  );
}

export default InformationTechnologyHome;
