const api_gateway_url = process.env.REACT_APP_ENV == 'local' ? process.env.REACT_APP_API_GATEWAY_URL : "";
const api_gateway_key = process.env.REACT_APP_ENV == 'local' ? process.env.REACT_APP_API_GATEWAY_SUBSCRIPTION_KEY : "";

const headers = process.env.REACT_APP_ENV === "local" ? {
    "Ocp-Apim-Subscription-Key": api_gateway_key
} : {};

export async function fetchPowerBIEmbed() {
    try {
        const response = await fetch(
            `${api_gateway_url}/api/atcfuncapp/GetEmbeddedPowerBI`,
            {
                method: "GET",
                headers,
            }
        );
        if (!response.ok) {
            console.log(response)
            throw new Error(`${response.status} ${response.statusText}`);
        }
        return response.json();
    } catch (error) {
        throw error;
    }
}
