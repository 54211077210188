import React from "react";
import ContentSection from "../content-section";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { getEntriesByContentType } from "../../services/contentful-service";

const contentType = "newsAndPublications";
function Publications() {
    const [publications, setPublications] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        document.querySelector('.content-section').classList.remove('content-section');
        const result = await getEntriesByContentType(contentType);
        // create a map of [asset IDs, assets] for efficient lookup
        //const assetUrlMap = result.includes.Asset.reduce((acc, asset) => {
        //    acc[asset.sys.id] = asset.fields.file.url;
        //    return acc;
        //}, {});

        const allPublications = result
            .filter((item) => {
                return item.fields.category === 'Publication';
            })
            .map((item) => {
                return {
                    title: item.fields.title,
                    sortOrder: item.fields.sortOrder,
                    creationDate: item.fields.creationDate,
                    documentPath: "https:" + item.fields.document.fields.file.url, //assetUrlMap[item.fields.document.sys.id],
                };
            })
            .sort((a, b) => a.sortOrder - b.sortOrder);
        setPublications(allPublications);
    };

    const PublicationsSection = (props, index) => {
        return (
            <div className="list-group-item flex-column align-items-start">
                <div className="d-flex">
                    <div>{new Date(props.item.creationDate).toLocaleDateString()}</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div>{props.item.title}</div>
                    <Button className='btn' aria-label={`Read More ${props.item.title}`} component={Link} target="_blank" to={props.item.documentPath}><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></Button>
                </div>
            </div>
        );
    };

    return (
        <div className='news-publication position-relative'>
            <ContentSection header="All Publications">
                <div className="container justify-content-center align-content-center mt-md-4">
                    <div className="list-group  list-group-flush">
                        {publications.map((item, index) => (
                            <PublicationsSection key={index} item={item}></PublicationsSection>
                        ))}
                        <br /><br /><br /><br /><br />
                    </div>
                </div>

                <div className="custom-shape-divider-bottom-1679439189">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
                    </svg>
                </div>
            </ContentSection>
            </div>
    );
}
export default Publications;