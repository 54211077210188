import resolveResponse from 'contentful-resolve-response'
const api_gateway_url = process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_API_GATEWAY_URL : "";
const api_gateway_key = process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_API_GATEWAY_SUBSCRIPTION_KEY : "";
const spaceID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;

export const getEntriesByContentType = async (contentType) => {
  const headers = process.env.REACT_APP_ENV === "local" ? {
    "Ocp-Apim-Subscription-Key": api_gateway_key
  } : {};

  try {
    const response = await fetch(
      `${api_gateway_url}/api/cms-delivery/spaces/${spaceID}/environments/${environment}/entries?content_type=${contentType}`,
      {
        method: "GET",
        headers,
      }
    );
      const result = resolveResponse(await response.json());
    return result;
  } catch (error) {
    console.error("Error fetching Contentful data:", error);
    return null;
  }
};
