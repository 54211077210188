import { connect } from "react-redux";
import LoginButton from "../components/nav-bar/login-button.js";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const LoginButtonContainer = connect(mapStateToProps)(LoginButton);

export default LoginButtonContainer;
