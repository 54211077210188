import React, { useEffect, useRef } from 'react';

let tvScriptLoadingPromise;

export default function TradingViewWidget() {
    const onLoadScriptRef = useRef();

    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_69ede') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        width: "100%",
                        height: "400",
                        symbol: "DJI",
                        interval: "1",
                        timezone: "America/Los_Angeles",
                        theme: "light",
                        style: "2",
                        locale: "en",
                        enable_publishing: false,
                        allow_symbol_change: true,
                        container_id: "tradingview_69ede"
                    });
                }
            }
        },
        []
    );

    return (
        <>
            {/* <h1>Dow Jones</h1> */}
            <div className='tradingview-widget-container' style={{ height: "100%", width: "100%" }}>
                <div id='tradingview_69ede' style={{ height: "calc(100% - 32px)", width: "100%" }} />
                <div className="tradingview-widget-copyright">
                    <a href="https://www.tradingview.com/" rel="noreferrer noopener nofollow" target="_blank"></a>
                </div>
            </div>
        </>
    );
}






