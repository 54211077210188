import React from "react";
import NavTiles from "../../components/treasurer/nav-tiles";
import ChiefMessage from "../../components/treasurer/chief-message";
import DowJones from "../../components/treasurer/dow-jones";
import NewsTicker from "../../components/treasurer/news-ticker";
import { RFI } from "./rfi";
import TreasuryYieldGraph from "../../components/treasurer/market-update";
import { Divider, Typography } from "@mui/material";
import AlertService from "../../components/alert-service/alert-service";

function TreasurerHome(props) {
    return (
        // uncomment this line and comment the div below to see RFI
        // <RFI />
        <div>
            <div className="container treasurer mt-5">
                {/* alerts */}
                <div className="mb-4">
                    <AlertService divisionCode="TRE"></AlertService>
                </div>
                <div className="row">
                    <ChiefMessage />
                </div>
                <div className="row">
                    <NavTiles />
                </div>
                <Divider />
                <div className="row downjones-graph  my-2 pb-5">
                    <Typography variant="h6" mb={1} color="gray">
                        Dow Jones
                    </Typography>
                    <DowJones />
                </div>
                <Divider />
                <div className="row my-2 pb-5">
                    <Typography variant="h6" mb={1} color="gray">
                        Treasury Market Update
                    </Typography>
                    <TreasuryYieldGraph />
                </div>
               

            </div>
            <NewsTicker />
        </div>
    );
}

export default TreasurerHome;
