import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { Line } from "react-chartjs-2";
import auto from "chart.js/auto";
export default function TreasuryYieldGraph() {
  const [selectedChartType, setSelectedChartType] = useState("3m");
  const newStockData = [];
  const [entireData, setEntireData] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Three-Month Yield",
        data: [],
        borderColor: "blue",
        fill: false,
        tension: 0.5,
      },
      {
        label: "Six-Month Yield",
        data: [],
        borderColor: "red",
        fill: false,
        tension: 0.5,
      },
      {
        label: "One-Year Yield",
        data: [],
        borderColor: "green",
        fill: false,
        tension: 0.5,
      },
      {
        label: "Three-Year Yield",
        data: [],
        borderColor: "orange",
        fill: false,
        tension: 0.5,
      },
      {
        label: "Five-Year Yield",
        data: [],
        borderColor: "purple",
        fill: false,
        tension: 0.5,
      },
    ],
    title: {
      display: true,
      text: "Treasury Yield Curve Rate",
    },
  });

  let PageNumber = 0;

  useEffect(() => {
    if (entireData.length === 0) {
      GetData();
      //jsonGetData();
    }

    filterChartData(entireData);
  }, [selectedChartType]);
  //Pagnation interation through treasury API for historical data on daily treasury yield
  function GetData() {
    fetch(
      `https://home.treasury.gov/resource-center/data-chart-center/interest-rates/pages/xml?data=daily_treasury_yield_curve&field_tdr_date_value=all&page=${PageNumber}`
    )
      .then((response) => response.text())
      .then((xmlData) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlData, "text/xml");
        const entries = xmlDoc.getElementsByTagNameNS("*", "entry");
        //console.log(entries[0])
        if (entries[0] != null) {
          for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            const properties = entry.querySelector("properties");
            const data = {
              date: properties.querySelector("NEW_DATE").textContent,
              three_Month: parseFloat(
                properties.querySelector("BC_3MONTH").textContent
              ),
              six_Month: parseFloat(
                properties.querySelector("BC_6MONTH").textContent
              ),
              one_Year: parseFloat(
                properties.querySelector("BC_1YEAR").textContent
              ),
              three_Year: parseFloat(
                properties.querySelector("BC_3YEAR").textContent
              ),
              five_Year: parseFloat(
                properties.querySelector("BC_5YEAR").textContent
              ),
            };
            newStockData.push(data);
          }
          if (PageNumber === 14 || PageNumber === 16) {
            PageNumber += 1;
          }
          PageNumber++;
          GetData();
          return;
        }
        //console.log(newStockData)
        filterChartData(newStockData);
        setEntireData(newStockData);
      })
      .catch((error) => {
        console.error("Error fetching and parsing XML data:", error);
        console.log(`Error happened at ${PageNumber}`);
        setEntireData(newStockData);
      });
  }
  //filter data to display based on Selected timeframe
  function filterChartData(newStockData) {
    const now = new Date();

    let startDate;
    if (selectedChartType === "3m") {
      startDate = new Date(
        now.getFullYear(),
        now.getMonth() - 3,
        now.getDate()
      );
    } else if (selectedChartType === "6m") {
      startDate = new Date(
        now.getFullYear(),
        now.getMonth() - 6,
        now.getDate()
      );
    } else if (selectedChartType === "1y") {
      startDate = new Date(
        now.getFullYear() - 1,
        now.getMonth(),
        now.getDate()
      );
    } else if (selectedChartType === "3y") {
      startDate = new Date(
        now.getFullYear() - 3,
        now.getMonth(),
        now.getDate()
      );
    } else if (selectedChartType === "5y") {
      startDate = new Date(
        now.getFullYear() - 5,
        now.getMonth(),
        now.getDate()
      );
    } else if (selectedChartType === "All") {
      startDate = new Date(
        now.getFullYear() - 100,
        now.getMonth(),
        now.getDate()
      );
    }

    let filteredData = newStockData.filter(
      (data) => new Date(data.date) >= startDate
    );

    if (["All"].includes(selectedChartType)) {
      let length = filteredData.length;
      filteredData = filteredData.filter((_, index) => index % 10 === 0);
    }
    //console.log(filteredData)
    const labels = filteredData.map((data) => {
      const date = new Date(data.date);
      const month = new Intl.DateTimeFormat("en", {
        month: "short",
      }).format(date);
      const year = date.getFullYear();
      return `${month} ${date.getDate()}, ${year}`;
    });
    const datasetsData = [
      filteredData.map((data) => ({
        x: new Date(data.date).getTime(),
        y: data.three_Month,
      })),

      filteredData.map((data) => ({
        x: new Date(data.date).getTime(),
        y: data.six_Month,
      })),
      filteredData.map((data) => ({
        x: new Date(data.date).getTime(),
        y: data.one_Year,
      })),
      filteredData.map((data) => ({
        x: new Date(data.date).getTime(),
        y: data.three_Year,
      })),
      filteredData.map((data) => ({
        x: new Date(data.date).getTime(),
        y: data.five_Year,
      })),
    ];

    setChartData((prevData) => {
      const updatedDatasets = prevData.datasets.map((dataset, index) => ({
        ...dataset,
        data: datasetsData[index],
        options: {
          animation: false,
        },
      }));

      return {
        labels: labels,
        datasets: updatedDatasets,
        title: {
          display: true,
          text: "Treasury Par Yield Curve Rate",
        },
      };
    });
  }

  const chartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Interest Rate",
        },
        beginAtZero: false,
      },
    },
    plugins: {
      legend: false,
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        position: "average",
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + "%";
            }
            return label;
          },
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const handleChartType = (event, newChartType) => {
    if (newChartType !== null) {
      setSelectedChartType(newChartType);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "white" }}>
      <div style={{ padding: "5px" }}>
        <div style={{ padding: "5px" }}>
          <Stack
            direction={{ xs: "column", xl: "row" }}
            spacing={1}
            justifyContent="flex-start"
          >
            <Box sx={{ flexGrow: 1 }}>
              <Stack direction="row">
                <ToggleButtonGroup
                  sx={{ width: "100%", paddingLeft: "0rem" }}
                  value={selectedChartType}
                  exclusive
                  onChange={handleChartType}
                  aria-label="chart type"
                  fullWidth
                >
                  {["3m", "6m", "1y", "3y", "5y", "All"].map((type) => (
                    <ToggleButton
                      key={type}
                      value={type}
                      aria-label="left aligned"
                      sx={{ fontSize: "1rem" }}
                      fullWidth
                    >
                      {type}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>

              <Line data={chartData} options={chartOptions} />
            </Box>
          </Stack>
        </div>
      </div>
    </Box>
  );
}
