import React from "react";
import InformationalTiles from "../../components/treasurer/informational-tiles";
import { BsBank2, BsFillPiggyBankFill } from "react-icons/bs";
import { MdPolicy } from "react-icons/md";
import { FaMoneyBill1Wave } from "react-icons/fa6";
import { FaFileContract, FaSchool } from "react-icons/fa";
import { RiServiceFill } from "react-icons/ri";
import { cardColors } from "../../utils/utils";
import StaticPageContainer from "../../components/static-page";
import { Typography } from "@mui/material";

const LegallyMandatedDuties = () => {
  return (
    <StaticPageContainer title="Legally Mandated Duties">
      <InformationalTiles
        title={"Banking Services"}
        icon={<BsBank2 />}
        bgColor={cardColors.two}
        side={"left"}
        information={
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Section 27000 of the Government Code requires the county treasurer to "receive and keep safely
              all money belonging to the county and all other money directed by law to be paid to him and
              apply and pay it out, rendering the account as required by law."
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Section 27002 further requires the treasurer to "keep an account of the receipt and expenditure
              of all money received or paid out by him or her in books provided for the purpose."
            </Typography>
          </>
        }
      />
      <InformationalTiles
        title={"Investment of Funds"}
        icon={<BsFillPiggyBankFill />}
        bgColor={cardColors.three}
        side={"right"}
        information={
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Pursuant to Section 27000.1 of the Government Code and as delegated by the Board of Supervisors,
              the Treasurer is the authority responsible for the investment and reinvestment of the funds of
              the county and the funds of other depositors in the county treasury, pursuant to Chapter 4
              (commencing with Section 53600) of Part 1 of Division 2 of Title 5. As such, the county
              treasurer is held to the prudent investor standard of the law. The county treasurer shall
              thereafter assume full responsibility for those transactions until the board of supervisors
              either revokes its delegation of authority, by ordinance, or decides not to renew the annual
              delegation, as provided in Section 53607.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Section 53601 defines those surplus monies of local agencies which may be invested in defined
              types of investments set by the State.
            </Typography>
          </>
        }
      />
      <InformationalTiles
        title={"County Treasury Oversight Committee"}
        icon={<MdPolicy />}
        bgColor={cardColors.five}
        side={"left"}
        information={
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Pursuant to Sections 27130 through 27137, each county in the State should annually adopt a{" "}
              <a href="">County Investment Policy</a> and also staff a treasury oversight committee to review,
              approve and monitor compliance with the investment policy. The Investment Policy is submitted to
              the Board of Supervisors during FYQ4 for approval prior to current fiscal year end for the
              upcoming July 1–June 30 FY.
            </Typography>
          </>
        }
      />
      <InformationalTiles
        title={"Deposit of Funds"}
        icon={<FaMoneyBill1Wave />}
        bgColor={cardColors.four}
        side={"right"}
        information={
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Pursuant to Sections 53630 through 53684, the responsibility for the depositing of funds is
              assigned to the county treasurer. In these sections, funds are defined, deposits and securities
              for deposits are classified and numerous reporting requirements are detailed. Additionally,
              contracts with depositories and alternative procedures for the investment of excess funds are
              given.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Section 53684 requires the treasurer to compute interest owed to the treasury and apportion
              interest to pool participants.
            </Typography>
          </>
        }
      />
      <InformationalTiles
        title={"Temporary Borrowing"}
        icon={<FaFileContract />}
        bgColor={cardColors.one}
        side={"left"}
        information={
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Government Code Section 53850 authorizes the county to issue short-term notes for the purposes
              of temporary borrowing. The county treasurer has overseen the issuance of these TRANs and
              investment of the proceeds in San Bernardino County.
            </Typography>
          </>
        }
      />
      <InformationalTiles
        title={"Oversight of Local Agencies"}
        icon={<FaSchool />}
        bgColor={cardColors.two}
        side={"right"}
        information={
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              The county treasurer has certain oversight responsibilities for local agencies, primarily school
              districts and community college districts, within the county. Pursuant to Sections 53821, the
              Treasurer must review all temporary borrowings of any school district, county board of
              education, or community college district. <a href="">Click here</a> for the 2012 School Finance
              Handbook.
            </Typography>
          </>
        }
      />
      <InformationalTiles
        title={"Trustee Services"}
        icon={<RiServiceFill />}
        bgColor={cardColors.three}
        side={"left"}
        information={
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Pursuant to Section 27000.1 of the Government Code and as delegated by the Board of Supervisors,
              the Treasurer is the authority responsible for the investment and reinvestment of the funds of
              the county and the funds of other depositors in the county treasury, pursuant to Chapter 4
              (commencing with Section 53600) of Part 1 of Division 2 of Title 5. As such, the county
              treasurer is held to the prudent investor standard of the law. The county treasurer shall
              thereafter assume full responsibility for those transactions until the board of supervisors
              either revokes its delegation of authority, by ordinance, or decides not to renew the annual
              delegation, as provided in Section 53607.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Section 53601 defines those surplus monies of local agencies which may be invested in defined
              types of investments set by the State.
            </Typography>
          </>
        }
      />
    </StaticPageContainer>
  );
};

export default LegallyMandatedDuties;
