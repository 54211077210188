import React from "react";
import HomepageTemplate from "../../components/division-homepage/division-homepage-template";
import { Box, Card } from "@mui/material";
import { titleFontSize, subtitleFontSize, textFontSize } from "../../utils/utils";
import BasicWhiteCard from "../../components/basic-white-card";

function DisbursementsHome(props) {
  const tileList = [
    { title: "Accounts Payable", color: "four", link: "#" },
    { title: "Central Payroll", color: "five", link: "#" },
  ];

  return (
    <HomepageTemplate
      divisionCode="DSB"
      division="Disbursements"
      chiefImage="assets/images/team/disb-chief.png"
      chiefName="Rhawnie Berg"
      divisionIntro="Rhawnie Berg has served as ATC Chief Deputy Disbursements since October 2021. Rhawnie began her career with the Auditor-Controller/Treasurer/Tax Collector in 2005, as an Accountant I in the Internal Audits Section. She promoted through the ranks, serving in several different capacities throughout the department, including time in the Central Payroll, Accounts Payable, and Internal Audits Sections, as well as the Property Tax Division. Rhawnie holds a B.S in Accounting from California State University, San Bernardino, and a professional designation as an Accredited Payables Manager (APM)."
      // aboutLink="#"
      tileList={tileList}
      // linkList={linkList}
    />
  );
}

export default DisbursementsHome;
