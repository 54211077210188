import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Card } from "@mui/material";
import { titleFontSize, subtitleFontSize, textFontSize } from "../../utils/utils";

function InformationTechnologyAbout() {
  return (
    <div
      style={{
        witdh: "100vw",
        display: "grid",
        justifyContent: "center",
        lineHeight: "1.7",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          border: "none",
          padding: { xs: "40px", sm: "35px 15vw 55px" },
          fontSize: { xs: "medium", sm: "large" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="mb-5">
          <blockquote className="blockquote" style={{ textAlign: "justify" }}>
            <h1 className="text-center mb-4" style={{ fontSize: titleFontSize, overflowWrap: "anywhere" }}>
              Welcome to the Information Technology Division of San Bernardino County's
              Auditor/Controller/Treasurer/Tax-Collector.
            </h1>
            <div style={{ fontSize: textFontSize }}>
              <p style={{ paddingBottom: "20px" }}>
                The Information Technology Division provides technical leadership and support for all
                divisions within the Auditor-Controller/Treasurer/Tax Collector operations. The primary goal
                of the IT Division is to provide exceptional customer service to all users within the
                Department and to ensure that they have the tools to perform their jobs in an efficient and
                productive manner.
              </p>
              <div style={{ paddingBottom: "20px" }}>
                <h3 style={{ fontSize: subtitleFontSize }}>Support:</h3>
                <p>
                  The IT Division provides support for desktop and laptop computers, as well as Windows
                  tablets, printers, multifunction devices, and cellular phones. ITD also supports enterprise
                  server hardware and software, network hardware, and other third-party systems.
                </p>
              </div>
              <div style={{ paddingBottom: "20px" }}>
                <h3 style={{ fontSize: subtitleFontSize }}>Development:</h3>
                <p>
                  The IT Division develops and supports a number of applications that enhance the unique
                  business needs of the Department. Many of these applications are utilized on a daily basis
                  to provide information and services to the public.
                </p>
              </div>
              <div style={{ paddingBottom: "20px" }}>
                <h3 style={{ fontSize: subtitleFontSize }}>Innovation:</h3>
                <p>
                  The IT Division challenges the standards of typical public sector operations by seeking out
                  the latest technologies to enhance the working environment of all ATC users. The IT Division
                  has introduced initiatives to help automate processes with the goal of reducing paper and
                  toner waste while achieving higher levels of productivity.
                </p>
              </div>
            </div>
          </blockquote>
        </div>

        <div className="mb-5 pb-2">
          <Card elevation={2}>
            <h3 className="text-center m-4 mb-0" style={{ fontSize: subtitleFontSize }}>
              Information Technology's Chief Deputy
            </h3>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" } }}>
              <img
                src="assets/images/team/it-chief.png"
                alt="Don Le"
                style={{
                  objectFit: "contain",
                  width: "max(150px + 4vw, 150px)",
                  maxWidth: "300px",
                  margin: "30px",
                }}
              />
              <blockquote className="blockquote mt-3 mb-5 mx-4" style={{ fontSize: textFontSize }}>
                <p>
                  Don Le has served as Chief Deputy, Information Technology for San Bernardino County
                  Auditor-Controller/Treasurer/Tax Collector since July 2019. He is responsible for the
                  management of Information Technology Division (ITD) and leading the division through many
                  ongoing projects for the department to meet current and future organizational challenges.
                </p>
                <p>
                  Don has more than sixteen years of experience in software engineering, IT infrastructure,
                  data science, cybersecurity, and IT management. He is a Certified Cybersecurity Information
                  System Security Professional (CISSP). Don holds both a Bachelor of Science and Master of
                  Science in Computer Science from California State University, San Bernardino, and is a Ph.D.
                  Candidate in Information Systems and Technology - Analytics and Decision Support at Dakota
                  State University. His research interests include machine learning and analytics with
                  applications in problem domain areas such as healthcare, project management, and security
                  planning and management.
                </p>
              </blockquote>
            </Box>
          </Card>
        </div>

        <Button variant="contained" component={Link} to="/information-technology">
          Return to Information Technology Home
        </Button>
      </Card>
    </div>
  );
}

export default InformationTechnologyAbout;
