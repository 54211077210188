import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom/dist";
import { AppBar, Box, Button, IconButton, Toolbar } from "@mui/material";
import NavbarDrawer from "./navbar-drawer";
import LoginButtonContainer from "../../containers/login-button-container";
import { divisionItemsList } from "../../utils/division-items";
import { onlineServiceItemsList } from "../../utils/online-services-items";
import MenuTemplate from "./styled-menus";
import { navbarList } from "../../utils/navbar-items";

Navigation.propTypes = {};

function Navigation(props) {
  const [isOnlineServicesOpen, setIsOnlinesServicesOpen] = useState(false);
  const [isDivisionsOpen, setIsDivisionsOpen] = useState(false);
  const [bottomAnchor, setBottomAnchor] = useState(null);
  const navbarRef = useRef(null);

  const handleDivisionClick = (event) => {
    setIsDivisionsOpen(true);
    setIsOnlinesServicesOpen(false);
  };

  const handleOnlineServicesClick = (event) => {
    setIsOnlinesServicesOpen(true);
    setIsDivisionsOpen(false);
  };

  const handleCloseMenu = () => {
    setIsDivisionsOpen(false);
    setIsOnlinesServicesOpen(false);
  };

  useEffect(() => {
    if (navbarRef.current) {
      const bottomPosition = navbarRef.current.getBoundingClientRect().bottom;
      setBottomAnchor(bottomPosition);
    }
  }, []);

  return (
    <AppBar ref={navbarRef} className="position-sticky">
      <Toolbar disableGutters>
        <Box className="nav-wrapper" sx={{ display: { xs: "none", md: "flex" } }}>
          <IconButton className="nav-logo-wrapper" edge="start" color="inherit" aria-label="menu">
            <img className="nav-logo" src="/assets/logos/sb-logo-full-color.png" alt="SBC ATC Logo" />
          </IconButton>
          <div className="d-flex mx-3 justify-content-around">
            {navbarList.map((item, i) => (
              <Button
                key={i}
                variant="Text"
                component={RouterLink}
                to={item.subheaderLink}
                onClick={
                  item.subheader !== "Online Services" && item.subheader !== "Divisions"
                    ? handleCloseMenu
                    : null
                }
                onMouseOver={
                  item.subheader === "Online Services"
                    ? handleOnlineServicesClick
                    : item.subheader === "Divisions"
                    ? handleDivisionClick
                    : null
                }
                sx={{ textAlign: "center" }}
              >
                {item.subheader}
              </Button>
            ))}

            {/* menus */}
            <MenuTemplate
              id="online-services-menu"
              isOpen={isOnlineServicesOpen}
              handleCloseMenu={handleCloseMenu}
              itemsList={onlineServiceItemsList}
              bottomAnchor={bottomAnchor}
            />
            <MenuTemplate
              id="divisions-menu"
              isOpen={isDivisionsOpen}
              handleCloseMenu={handleCloseMenu}
              itemsList={divisionItemsList}
              bottomAnchor={bottomAnchor}
            />
          </div>
          <div className="d-flex">
            {/* <Button type="button" variant="Text" className="mx-3">
                Log In
              </Button> */}
            <LoginButtonContainer />
          </div>
        </Box>
        <Box className="nav-wrapper" sx={{ display: { sm: "flex", md: "none" } }}>
          <NavbarDrawer drawerWidth={0}></NavbarDrawer>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;
