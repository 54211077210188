import React from 'react';
import Bio from "../../components/bio/bio";
import WelcomeSection from '../../components/welcome';

const data = [{ desc: "The Auditor-Controller/ Treasurer/ Tax Collector is the elected official who is responsible for determining property tax rates and bill amounts, the administration of the County Investment Pool, and the collection of all property tax bills in the County." },
{ desc: "This office is committed to serving our customers by processing, safeguarding, and providing information regarding the finances and public records of the County. We perform these functions with integrity, independent judgment, and outstanding service. We are accurate, timely, courteous, innovative, and efficient because of our well-trained and accountable staff. The Annual Comprehensive Financial Reports and the Popular Annual Financial Reports published by this office have consistently received the Government Finance Officers Association awards for excellence." },
{
    desc: "The County of San Bernardino is a regional government. We understand that every aspect of the quality"
        + " of life in a community is part of an interrelated system.The County takes responsibility for ensuring that the community"
        + " has determined how, when and by whom each element of that system is being addressed in pursuit of our shared Vision"
        + " of the County and the future we desire."
}]

const About = () => {

    return (
        <WelcomeSection>
            <div className='container'>
                <div className="d-flex justify-content-center align-items-center about-atc">
                    <div className='about-content my-5'>
                        <h1 className='display-4 text-center mb-5'>About Auditor-Controller/Treasurer/Tax Collector</h1>
                        <div className="LeftBioSection my-2">
                            <Bio imgPositionLeft={true} imgSrc="/assets/images/building/ATC_20_037.jpg" imgHeight="220" imgWidth="300" title="What is Auditor-Controller/Treasurer/Tax Collector?" description={data[0].desc}></Bio>
                        </div>
                        <div className="RightBio my-2">
                            <Bio imgPositionLeft={false} imgSrc="/assets/images/building/ATC_20_059.jpg" imgHeight="220" imgWidth="300" title="Our Purpose" description={data[1].desc}></Bio>
                        </div>
                        <div className="LeftBioSection my-2">
                            <Bio imgPositionLeft={true} imgSrc="/assets/images/building/atc3.jpg" imgHeight="220" imgWidth="300" title="Auditor-Controller/Treasurer/Tax Collector Mission Statement" description={data[2].desc}></Bio>
                        </div>
                    </div>
                </div>
                <div className='mb-5'>
                    <h3 className="text-center display-6 mb-5"> ATC History: A Look Back In Time 1853 - Present </h3>
                    <iframe title="atc history" src='https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=1BJ3JbgXLnt2UNCyMWp4QCiD7K0y2VJlh39TThbhLmSU&font=Default&lang=en&start_at_end=true&initial_zoom=2&height=650' width='100%' height='650' allowFullScreen frameBorder='0'></iframe>
                </div>
            </div>
        </WelcomeSection>
    )
}

export default About;