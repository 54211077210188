import React from "react";
import { Card } from "@mui/material";
import { subtitleFontSize, textFontSize } from "../utils/utils";

function BasicWhiteCard(props) {
  return (
    <div className="row">
      <div className="col my-2 pb-5">
        <Card elevation={2} className="px-5">
          {props.title && (
            <h3 className="text-center m-4 mb-0" style={{ fontSize: subtitleFontSize }}>
              {props.title}
            </h3>
          )}
          {props.content && (
            <blockquote className="blockquote p-4" style={{ fontSize: textFontSize }}>
              {props.content}
            </blockquote>
          )}
        </Card>
      </div>
    </div>
  );
}

export default BasicWhiteCard;
