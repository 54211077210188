import React from "react";
import { Link as RouterLink } from "react-router-dom/dist";
import { Box, Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

const MenuSubHeader = ({ title, icon, handleCloseMenu, ...props }) => (
  <Box sx={{ fontWeight: 700, padding: ".25rem" }}>
    <Button
      variant="text"
      sx={{ width: "100%", justifyContent: "flex-start" }}
      {...props}
      component={RouterLink}
      onClick={() => handleCloseMenu()}
    >
      <FontAwesomeIcon color="black" icon={icon} size="1x" />
      <Typography sx={{ ml: ".75rem", color: "#1c6889" }}>{title}</Typography>
    </Button>
  </Box>
);

const CustomMenuItem = ({ children, handleCloseMenu, ...props }) => (
  <MenuItem className="menu-item" component={RouterLink} onClick={() => handleCloseMenu()} {...props}>
    <Box sx={{ textTransform: "uppercase" }}>{children}</Box>
  </MenuItem>
);

const MenuTemplate = ({ id, isOpen, anchorEl, handleCloseMenu, itemsList, bottomAnchor, ...props }) => {
  return (
    bottomAnchor && (
      <Menu
        id={id}
        MenuListProps={{
          "aria-labelledby": { id },
          onMouseLeave: handleCloseMenu,
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: bottomAnchor, left: window.innerWidth / 2 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
        open={isOpen}
        onClose={handleCloseMenu}
        sx={{
          "& .MuiPaper-root": { borderRadius: 2 },
          zIndex: 1019 /* z-index of sticky navbar is 1020, but Menu is originally 1300 */,
        }}
      >
        <Box
          sx={{
            columnCount: id === "divisions-menu" ? { xs: 1, md: 2, xl: 3 } : { xs: 1, md: 2 },
            columnGap: "7em",
            padding: "0.5rem 3rem",
            justifyContent: "center",
          }}
        >
          {itemsList.map((list, i) => (
            <Box key={i} sx={{ breakInside: "avoid-column" }}>
              {list.subheader && (
                <MenuSubHeader
                  title={list.subheader}
                  icon={faBuilding}
                  to={list.subheaderLink}
                  handleCloseMenu={handleCloseMenu}
                ></MenuSubHeader>
              )}
              {list.items.map((item, j) => (
                <CustomMenuItem key={j} className="menu-item" to={item.itemLink} onClick={handleCloseMenu}>
                  {item.item}
                </CustomMenuItem>
              ))}
              <Divider />
            </Box>
          ))}
        </Box>
      </Menu>
    )
  );
};

export default MenuTemplate;
